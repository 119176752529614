import React from "react";
import { ReactComponent as BronzeIcon } from "asset/icon/rating/bronzeIcon.svg";
import { ReactComponent as SilverIcon } from "asset/icon/rating/silverIcon.svg";
import { ReactComponent as GoldIcon } from "asset/icon/rating/goldIcon.svg";
import { ReactComponent as PlatinumIcon } from "asset/icon/rating/platinumIcon.svg";
import { ReactComponent as DiamondIcon } from "asset/icon/rating/diamondIcon.svg";
import { ReactComponent as MasterIcon } from "asset/icon/rating/masterIcon.svg";

interface Props {
  level: number;
}

export function UserLevel({ level }: Props) {
  switch (level) {
    case 0:
      return (
        <BronzeIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
    case 1:
      return (
        <SilverIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
    case 2:
      return (
        <GoldIcon style={{ transform: "scale(0.888)", marginBottom: "3px" }} />
      );
    case 3:
      return (
        <PlatinumIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
    case 4:
      return (
        <DiamondIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
    case 5:
      return (
        <MasterIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
    default:
      return (
        <BronzeIcon
          style={{ transform: "scale(0.888)", marginBottom: "3px" }}
        />
      );
  }
}
