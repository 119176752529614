import {
  UserOutlined,
  LaptopOutlined,
  CreditCardOutlined,
  ReadOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    key: "user",
    icon: <UserOutlined />,
    label: "사용자 관리",
    path: "/user",
    children: [
      {
        key: "/user/member",
        path: "/member",
        label: "회원 관리",
      },
      {
        key: "/user/manager",
        path: "/manager",
        label: "운영자 관리",
      },
    ],
  },
  {
    key: "contents",
    icon: <LaptopOutlined />,
    label: "컨텐츠 관리",
    path: "contents",
    children: [
      {
        key: "/contents/lecture",
        path: "/lecture",
        label: "강의",
      },
      {
        key: "/contents/video",
        path: "/video",
        label: "영상",
      },
      {
        key: "/contents/problem",
        path: "/problem",
        label: "문제",
      },
      {
        key: "/contents/category",
        path: "/category",
        label: "문제 카테고리",
      },
      {
        key: "/contents/roadmap",
        path: "/roadmap",
        label: "로드맵",
      },
      {
        key: "/contents/banner",
        path: "/banner",
        label: "배너",
      },
      {
        key: "/contents/popular_lecture",
        path: "/popular_lecture",
        label: "인기강의",
      },
    ],
  },
  {
    key: "memberships",
    icon: <CreditCardOutlined />,
    label: "멤버쉽 관리",
    path: "membership",
    children: [
      {
        key: "/membership/price",
        path: "/price",
        label: "멤버쉽 가격",
      },
      {
        key: "/membership/list",
        path: "/list",
        label: "결제 내역",
      },
    ],
  },
  {
    key: "community",
    icon: <ReadOutlined />,
    label: "커뮤니티 관리",
    path: "community",
    children: [
      {
        key: "/community/qna",
        path: "/qna",
        label: "Q&A",
      },
      {
        key: "/community/study",
        path: "/study",
        label: "스터디",
      },
      {
        key: "/community/knowledge",
        path: "/knowledge",
        label: "지식공유",
      },
      {
        key: "/community/review",
        path: "/review",
        label: "수강후기",
      },
      {
        key: "/community/inform",
        path: "/inform",
        label: "공지사항",
      },
    ],
  },
  {
    key: "terms",
    icon: <ExceptionOutlined />,
    label: "약관 관리",
    path: "terms",
  },
];
