import AxiosManager from "../_axiosManager";

export async function postVideo(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/video", body);

  return res;
}

export async function putVideo(id: string, body: any) {
  const res = await AxiosManager.getInstance().put(`/admin/video/${id}`, body);

  return res;
}

export async function postProblem(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/problem", body);

  return res;
}

export async function putProblem(problemId: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/problem/${problemId}`,
    body,
  );

  return res;
}

export async function postBanner(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/banner", body);

  return res;
}

export async function putBanner(bannerId: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/banner/${bannerId}`,
    body,
  );

  return res;
}

export async function postLecture(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/lecture", body);

  return res;
}

export async function putLecture(lectureId: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/lecture/${lectureId}`,
    body,
  );

  return res;
}

export async function postRoadmap(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/loadmap", body);

  return res;
}

export async function putRoadmap(id: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/loadmap/${id}`,
    body,
  );

  return res;
}

export async function postProbleCategory(body: any) {
  const res = await AxiosManager.getInstance().post("/problemcategory", body);

  return res;
}

export async function putProblemCategory(id: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/problemCategory/${id}`,
    body,
  );

  return res;
}

export async function postPopularLecture(lectureIds: number[]) {
  const res = await AxiosManager.getInstance().post("/admin/popularLecture", {
    lectureIds,
  });

  return res;
}
