import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getProblemCategory } from "service/api/content/getApi";
import { TableInput } from "components/input";
import { CategoryDropdown } from "./categoryDropdown";
import { LanguageCheckBox } from "./languageCheckBox";
import { InputCard } from "./inputCard";
import { IChangeData } from "./detail";

interface Props {
  data: IChangeData;
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
}

const Cover = styled.div`
  .title {
    margin: 0 0 22px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-size: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .inputCard {
    display: flex;
    gap: 4px;
    height: 76px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export function ProblemDetailTable({ data, setData }: Props) {
  const [categoryList, setCategoryList] = useState<
    { name: string; id: number }[]
  >([]);

  useEffect(() => {
    (async () => {
      const res = await getProblemCategory();
      if (res.status === 200) {
        setCategoryList(
          res.data.data.map((list: any) => ({ name: list.name, id: list.id })),
        );
      }
    })();
  }, []);

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleLoopInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      limitLoop: e.target.value,
    }));
  };

  return (
    <Cover>
      <div className="title">기본정보*</div>
      <Table>
        <tbody>
          <tr>
            <th>제목</th>
            <td colSpan={3}>
              <TableInput value={data.title} onChange={handleTitleInput} />
            </td>
          </tr>
          <tr>
            <th>카테고리</th>
            <td>
              <CategoryDropdown
                categoryList={categoryList}
                name="category"
                category={data?.category}
                setCategory={setData}
              />
            </td>
            <th>시간제한(s)</th>
            <td className="inputCard">
              <InputCard
                label="C"
                value={data.limitSeconds?.C}
                name="limitSeconds"
                language="C"
                setValue={setData}
              />
              <InputCard
                label="C++"
                value={data.limitSeconds?.Cpp}
                name="limitSeconds"
                language="Cpp"
                setValue={setData}
              />
              <InputCard
                label="Java"
                value={data.limitSeconds?.Java}
                name="limitSeconds"
                language="Java"
                setValue={setData}
              />
              <InputCard
                label="Python"
                value={data.limitSeconds?.Python}
                name="limitSeconds"
                language="Python"
                setValue={setData}
              />
            </td>
          </tr>
          <tr>
            <th>메모리 제한(MByte)</th>
            <td className="inputCard">
              <InputCard
                label="C"
                value={data.limitMemory?.C}
                name="limitMemory"
                language="C"
                setValue={setData}
              />
              <InputCard
                label="C++"
                value={data.limitMemory?.Cpp}
                name="limitMemory"
                language="Cpp"
                setValue={setData}
              />
              <InputCard
                label="Java"
                value={data.limitMemory?.Java}
                name="limitMemory"
                language="Java"
                setValue={setData}
              />
              <InputCard
                label="Python"
                value={data.limitMemory?.Python}
                name="limitMemory"
                language="Python"
                setValue={setData}
              />
            </td>
            <th>반복문 제한(기본 무한)</th>
            <td>
              <TableInput value={data.limitLoop} onChange={handleLoopInput} />
            </td>
          </tr>
          <tr>
            <th>언어 제한</th>
            <td colSpan={3}>
              <LanguageCheckBox data={data.language} setData={setData} />
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
