import { Routes, Route, Navigate } from "react-router-dom";
import { LoginPage } from "pages";
import { privateRoute } from "./routes.constant";
import { PrivateRoute } from "./routes.private";
import { LoginRoute } from "./routes.login";
import routerConstant from "service/constant/routerConstant";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={routerConstant.login} />} />
      <Route
        path={routerConstant.login}
        element={
          <LoginRoute>
            <LoginPage />
          </LoginRoute>
        }
      />
      {privateRoute.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={<PrivateRoute>{route.Component}</PrivateRoute>}
        />
      ))}
    </Routes>
  );
}
