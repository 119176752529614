import { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
Quill.register("modules/ImageResize", ImageResize);
// var SizeStyle = Quill.import("attributors/style/size");
// Quill.register(SizeStyle, true);
Quill.register(Quill.import("attributors/style/align"), true);

const BlockEmbed = Quill.import("blots/block/embed");

class keepHTML extends BlockEmbed {
  static create(node: any) {
    return node;
  }
  static value(node: any) {
    return node;
  }
}

keepHTML.blotName = "keepHTML";
keepHTML.className = "keepHTML";
keepHTML.tagName = "div";

Quill.register(keepHTML);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

class ImageFormat extends BaseImageFormat {
  static formats(domNode: any) {
    return ImageFormatAttributesList.reduce(function (
      formats: any,
      attribute: any,
    ) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    },
    {});
  }
  format(name: any, value: any) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { direction: "rtl" },
    ],
    [{ header: "1" }, { header: "2" }],
    ["link", "image"],
    [{ align: [] }, { color: [] }, { background: [] }],
    ["clean"],
  ],
  ImageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
};

export const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "header",
  "list",
  "bullet",
  "indent",
  "direction",
  "link",
  "image",
  "align",
  "color",
  "background",
  "width",
  "style",
];
