import React from "react";
import { Router } from "./Router";
import { useLocation } from "react-router-dom";
import { SWRConfig } from "swr";
import { SideBar } from "components/menu";
import AxiosManager from "service/api/_axiosManager";

function App() {
  const location = useLocation();
  const swrConfig = {
    fetcher(url: string, params: any) {
      return AxiosManager.getInstance()
        .get(url, params)
        .then((res) => res.data);
    },
  };

  return (
    <SWRConfig value={swrConfig}>
      {location.pathname.includes("/login") ? (
        <Router />
      ) : (
        <SideBar>
          <Router />
        </SideBar>
      )}
    </SWRConfig>
  );
}

export default App;
