import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { delRoadmap } from "service/api/content/getApi";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { RoadmapTable } from "./roadmapTable";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 33px 0 26px;
`;

export function RoadmapPage() {
  const navigate = useNavigate();
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const [selectPage, setSelectPage] = useState<number>(1);
  const [listData, setListData] = useState<{ count: number; data: [] }>({
    count: 0,
    data: [],
  });
  const { data, mutate } = useSWR(
    `/admin/loadmap?limit=30&offset=${(selectPage - 1) * 30}`,
  );

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.roadmap}/add`);
  };

  const handleDeleteBtn = async () => {
    const res = await delRoadmap(selectTable.join(";") + ";");
    mutate();
  };
  return (
    <Cover>
      <HeadText label="로드맵" />
      <BtnContainer>
        <DetailActionBtn
          label="선택 삭제"
          isFill={false}
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <RoadmapTable
        data={listData.data}
        count={listData.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
