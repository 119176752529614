import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { delKnowledge } from "service/api/community";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";
import useSWR from "swr";
import { QuestionContainer } from "./questionContainer";
import { AnswerContainer } from "./answerContainer";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin: 0 0 24px;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 36px 29px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
`;

export function KnowledgeDetailPage() {
  const naviage = useNavigate();
  const params = useParams();
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const [isErrModalVisible, setIsErrModalVisible] = useState<boolean>(false);
  const { data } = useSWR(`/admin/knowledge/${params.id}`);

  const handleBackBtn = () => {
    naviage(routerConstant.community.qna);
  };

  const handleDeleteBtn = async () => {
    const res = await delKnowledge(params.id + "");
    if (res.status === 200) {
      setIsSuccessModalVisible(true);
    } else if (res.status === 220) {
      setIsErrModalVisible(true);
    }
  };

  const handleSuccessBtn = () => {
    naviage(routerConstant.community.knowledge);
  };
  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="삭제 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <OneBtnModal
        isVisible={isErrModalVisible}
        setIsVisible={setIsErrModalVisible}
        text="권한이 없습니다"
      />
      <HeadText label="지식공유 상세" />
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleBackBtn}
        />
        <DetailActionBtn label="삭제하기" onClick={handleDeleteBtn} />
      </BtnContainer>
      <ContentContainer>
        <QuestionContainer
          title={data?.data.title}
          content={data?.data.content}
          profileImg={data?.data.User?.profileImg}
          profileName={data?.data.User?.nickName}
          level={data?.data.User?.level}
          createdAt={data?.data.createdAt}
          viewCount={data?.data.viewCount}
        />
        {data?.data.Comments?.length > 0 ? (
          <AnswerContainer Comments={data?.data.Comments} />
        ) : null}
      </ContentContainer>
    </Cover>
  );
}
