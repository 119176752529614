import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import styleConstant from "service/constant/styleConstant";
import "react-quill/dist/quill.bubble.css";

interface Props {
  height?: string;
  fontColor?: string;
  value: string;
}

const Editor = styled(ReactQuill)<{ height?: string; fontColor?: string }>`
  width: 100%;
  height: ${({ height }) => height};
  .ql-editor {
    padding: 0;
    font-family: ${styleConstant.FONT_FAMILY.SpoqaFont} !important;
  }
  .ql-size-small {
    font-size: 14px !important;
  }
  p {
    color: ${({ fontColor }) => fontColor && fontColor};
    font-size: 16px;
  }
  .ql-size-large {
    font-size: 22px;
  }
  .ql-size-huge {
    font-size: 24px;
  }
  img {
    margin: 30px 0 !important;
  }
  .ql-hr::after {
    font-size: 18px;
    line-height: 20px;
    content: "hr";
  }
  hr {
    border: 1px solid #f2f2f2;
  }
`;

export function ViewEditor({ height, value, fontColor }: Props) {
  return (
    <Editor
      value={value}
      height={height}
      fontColor={fontColor}
      theme="bubble"
      readOnly={true}
    />
  );
}
