import React from "react";
import styled from "styled-components";
import { SearchCategoryDropdown } from "components/dropdown";
import styleConstant from "service/constant/styleConstant";

interface Props {
  rightTitle: string;
  searchInputValue: string;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const Cover = styled.div`
  display: flex;
  /* gap: 40px; */
  justify-content: space-between;
  margin: 0;
  border: 1px solid #eee;
  border-left: none;

  .center {
    display: flex;
    /* gap: 20px; */
  }
`;

const Article = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* width: 120px; */
  height: 51px;
  padding: 13px 33px;
  background-color: #e8edf2;
  color: #6b7280;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 549px;
  height: 51px;
  padding: 16px;
  border: none;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: #8a92a6;
  }
`;

export function AddModalSearchTable({
  rightTitle,
  searchInputValue,
  setSearchInputValue,
}: Props) {
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  return (
    <Cover>
      <div className="center">
        <Article>{rightTitle}</Article>
        <Input
          value={searchInputValue}
          placeholder="검색어 입력"
          onChange={handleSearchInput}
        />
      </div>
    </Cover>
  );
}
