import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { putUserDetail } from "service/api/login/postApi";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { DetailTable } from "./detailTable";
import { MemoTable } from "./detailMemoTable";
import { DetailActionBtn } from "components/button";
import useSWR from "swr";
import moment from "moment";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 17px;
`;

export function MemberDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [changeData, setChangeData] = useState<{
    banCommunityDate: string;
    isActive: boolean;
    memo: string;
  }>({
    banCommunityDate: "",
    isActive: true,
    memo: "",
  });
  const [banCount, setBanCount] = useState<number>(0);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  const { data } = useSWR(`/admin/user/${params.id}`);

  useEffect(() => {
    if (data) {
      setChangeData({
        isActive: data.data.isActive,
        memo: data.data.Memo?.content,
        banCommunityDate: data.data.banCommunityDate,
      });
    }
  }, [data]);

  useEffect(() => {
    if (changeData.banCommunityDate) {
      setBanCount(
        moment(changeData?.banCommunityDate).diff(moment(), "days") + 1,
      );
    } else {
      setBanCount(0);
    }
  }, [changeData.banCommunityDate]);

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const handleSaveBtn = async () => {
    const res = await putUserDetail(params.id + "", {
      banCommunityDate:
        banCount === 0
          ? ""
          : `${String(
              moment().add(banCount, "days").format("YYYY-MM-DD"),
            )} 00:00:00`,
      isActive: changeData.isActive,
      memo: changeData.memo,
    });
    if (res.status === 200) {
      setIsSuccessModalVisible(true);
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.user.member);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />

      <HeadText label="회원 관리 상세" margin="0 0 38px" />
      <DetailTable
        data={data?.data}
        changeData={changeData}
        setChangeData={setChangeData}
        banCount={banCount}
        setBanCount={setBanCount}
      />
      <MemoTable changeData={changeData} setChageData={setChangeData} />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="목록으로"
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
