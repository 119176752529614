import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  onClick: () => void;
}

const Btn = styled.button`
  height: 44px;
  padding: 8px 24px;
  border: 1px solid #6c757d;
  background-color: #fff;
  color: #6c757d;
  font-size: 16px;
  cursor: pointer;
`;

export function CurriculumBtn({ label, onClick }: Props) {
  return <Btn onClick={onClick}>{label}</Btn>;
}
