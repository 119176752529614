import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { delReview } from "service/api/community";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { ReviewCard } from "./reviewCard";
import routerConstant from "service/constant/routerConstant";
import useSWR from "swr";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin: 0 0 56px;
`;

export function ReviewDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const [isErrModalVisible, setIsErrModalVisible] = useState<boolean>(false);

  const { data } = useSWR(`/admin/review/${params.id}`);

  const handleCancelBtn = () => {
    navigate(routerConstant.community.review);
  };

  const handleDeleteBtn = async () => {
    const res = await delReview(params.id + "");
    if (res.status === 200) {
      setIsSuccessModalVisible(true);
    } else if (res.status === 220) {
      setIsErrModalVisible(true);
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.community.review);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="삭제 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <OneBtnModal
        isVisible={isErrModalVisible}
        setIsVisible={setIsErrModalVisible}
        text="권한이 없습니다"
      />
      <HeadText label="수강후기 상세" />
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="삭제하기" onClick={handleDeleteBtn} />
      </BtnContainer>
      {data && (
        <ReviewCard
          name={data?.data.User?.nickName}
          starScore={data?.data.score}
          createdAt={data?.data.createdAt}
          lectureName={data?.data.Lecture?.title}
          review={data?.data.content}
          thumbnail={data?.data.Lecture?.thumbnail}
          profileImg={data?.data.User?.profileImg}
        />
      )}
    </Cover>
  );
}
