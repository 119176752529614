import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { delLecture } from "service/api/content/getApi";
import { HeadText } from "components/header";
import { SearchTable } from "components/table";
import { DetailActionBtn } from "components/button";
import { LectureTable } from "./lectureTable";
import routerConstant from "service/constant/routerConstant";

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 46px 0 26px;
`;

const LectureList: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [submitSearch, setSubmitSearch] = useState<string>("");
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const limit = 30;

  const { data, mutate } = useSWR(
    `/admin/lecture?limit=${limit}&offset=${offset}&search=${submitSearch}`,
  );

  useEffect(() => {
    if (searchInputValue.length === 0) {
      setSubmitSearch("");
    }
  }, [searchInputValue]);

  const handleSearchBtn = () => {
    setSubmitSearch(searchInputValue);
  };

  const handleDeleteBtn = async () => {
    const res = await delLecture(selectTable.join(";") + ";");
    if (res.status === 200) {
      mutate();
    }
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.lecture}/add`);
  };
  useEffect(() => {
    setOffset(limit * (page - 1));
    mutate();
  }, [page]);

  return (
    <>
      <HeadText label="강의" margin="0 0 25px" />
      <SearchTable
        rightTitle="검색어"
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        onClick={handleSearchBtn}
      />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="선택 삭제"
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      {data && (
        <LectureTable
          data={data}
          selectTable={selectTable}
          setSelectTable={setSelectTable}
          page={page}
          setPage={setPage}
          limit={limit}
        />
      )}
    </>
  );
};

export default LectureList;
