import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { getInformDetail, postInform, putInform } from "service/api/community";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { TableInput } from "components/input";
import Editor from "components/editor";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const Table = styled.table`
  width: 100%;
  margin: 51px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 913px;
    margin: 0;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .title {
    height: 75px;
    td {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      p {
        margin: 0;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
  .content {
    height: 273px;
    td {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      height: 273px;
      .length {
        margin: 0;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin: 142px 0 0;
`;

export function InformDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getInformDetail(params.id + "");
        if (res.status === 200) {
          setTitle(res.data.data.title);
          setContent(res.data.data.content);
          setIsUpdated(true);
        }
      })();
    }
  }, [params]);

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCancelBtn = () => {
    navigate(routerConstant.community.inform);
  };

  const handleSaveBtn = async () => {
    if (params.id === "add") {
      const res = await postInform({
        title,
        content,
      });
      if (res.status === 201) {
        setIsSuccessModalVisible(true);
      }
    } else {
      const res = await putInform(params.id + "", {
        content,
        title,
      });
      if (res.status === 200) {
        setIsSuccessModalVisible(true);
      }
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.community.inform);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <HeadText label="공지사항 상세" />
      <Table>
        <tbody>
          <tr className="title">
            <th>제목*</th>
            <td>
              <TableInput value={title} onChange={handleTitleInput} />
              <p>{title.length}/24</p>
            </td>
          </tr>
          <tr className="content">
            <th>내용*</th>
            <td>
              {!isUpdated && (
                <Editor
                  setValue={setContent}
                  height="203px"
                  margin="0 0 45px"
                />
              )}
              {isUpdated && (
                <Editor
                  value={content}
                  setValue={setContent}
                  height="203px"
                  margin="0 0 45px"
                />
              )}
              <p className="length">{content.length}/500</p>
            </td>
          </tr>
        </tbody>
      </Table>
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
