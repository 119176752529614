import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ReactComponent as LogoIcon } from "asset/icon/logo.svg";
import { LoginInput } from "./loginInput";
import { LoginBtn } from "./loginBtn";
import { postLogin } from "service/api/login/postApi";
import responseConstant from "service/constant/responseConstant";
import tokenConstant from "service/constant/tokenConstant";
import routerConstant from "service/constant/routerConstant";

interface ILogin {
  id: string;
  password: string;
}

const Cover = styled.div`
  width: 360px;
  margin: 0 auto;
  padding: 128px 16px;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 42px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  button {
    margin: 30px 0 0;
  }
`;

export function LoginPage() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ILogin>();

  const handleLoginForm = async (data: ILogin) => {
    const res = await postLogin({
      adminId: data.id,
      password: data.password,
    });

    if (res.status === responseConstant.OK) {
      localStorage.setItem(
        tokenConstant.ACCESS_TOKEN,
        res.data.data.accessToken,
      );
      localStorage.setItem(
        tokenConstant.REFRESH_TOKEN,
        res.data.data.refreshToken,
      );
      navigate(routerConstant.contents.lecture);
    } else {
      window.alert("비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <Cover>
      <Logo>
        <LogoIcon />
      </Logo>
      <FormContainer onSubmit={handleSubmit(handleLoginForm)}>
        <LoginInput
          placeholder="아이디"
          isError={errors.id?.type ? true : false}
          register={register("id", {
            required: true,
          })}
        />
        <LoginInput
          placeholder="비밀번호"
          type="password"
          isError={errors.password?.type ? true : false}
          register={register("password", {
            required: true,
          })}
        />
        <LoginBtn label="로그인" type="submit" />
      </FormContainer>
    </Cover>
  );
}
