import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCategoryDetail } from "service/api/content/getApi";
import {
  postProbleCategory,
  putProblemCategory,
} from "service/api/content/postApi";
import { useParams, useNavigate } from "react-router-dom";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { TableInput } from "components/input";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const CategoryTable = styled.table`
  width: 100%;
  margin: 35px 0 45px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export function CategoryDetailPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getCategoryDetail(params.id + "");
        setTitle(res.data.data.name);
      })();
    }
  }, [params]);

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCancelBtn = () => {
    navigate(routerConstant.contents.category);
  };

  const handleSaveBtn = async () => {
    if (title) {
      if (params.id === "add") {
        const res = await postProbleCategory({
          name: title,
        });
        if (res.status === 201) {
          setIsSuccessModalVisible(true);
        }
      } else {
        const res = await putProblemCategory(params.id + "", {
          name: title,
        });
        if (res.status === 200) {
          setIsSuccessModalVisible(true);
        }
      }
    } else {
      window.alert("항목을 입력해주세요");
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.contents.category);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      {params.id === "add" ? (
        <HeadText label="문제 카테고리 등록" />
      ) : (
        <HeadText label="문제 카테고리 수정" />
      )}
      <CategoryTable>
        <tr>
          <th>카테고리명*</th>
          <td>
            <TableInput
              value={title}
              onChange={handleTitleInput}
              placeholder="카테고리명 입력"
            />
          </td>
        </tr>
      </CategoryTable>
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
