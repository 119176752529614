import React from "react";
import styled from "styled-components";

interface Props {
  changeData: any;
  setChageData: React.Dispatch<React.SetStateAction<any>>;
}

const Cover = styled.div`
  margin: 0 0 30px;
  p {
    margin: 0;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 186px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 910px;
    height: 186px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
  }
  .community {
    height: 75px;
  }
`;

const Textarea = styled.textarea`
  width: 875px;
  height: 160px;
  margin: 0;
  padding: 8px 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  resize: none;
  outline: none;
  line-height: 175%;
  font-size: 16px;
`;

export function MemoTable({ changeData, setChageData }: Props) {
  const handleMemoTextareaChagne = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setChageData((prev: any) => ({
      ...prev,
      memo: e.target.value,
    }));
  };
  return (
    <Cover>
      <p>메모</p>
      <Table>
        <tbody>
          <tr>
            <th>메모</th>
            <td>
              <Textarea
                value={changeData.memo}
                onChange={handleMemoTextareaChagne}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
