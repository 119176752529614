import React, { useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { delBanner } from "service/api/content/getApi";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { BannerTabel } from "./bannerTable";
import routerConstant from "service/constant/routerConstant";

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 26px;
`;

export function BannerPage() {
  const navigate = useNavigate();
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const [selectPage, setSelectPage] = useState<number>(1);
  const { data, mutate } = useSWR(
    `/admin/banner?limit=30&offset=${(selectPage - 1) * 30}`,
  );

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.banner}/add`);
  };

  const handleDeleteBtn = async () => {
    if (selectTable.length > 0) {
      const res = await delBanner(selectTable.join(";") + ";");
      if (res.status === 200) {
        mutate();
      }
    }
  };
  return (
    <>
      <HeadText label="배너" margin="0 0 25px" />
      <BtnContainer>
        <DetailActionBtn
          label="선택 삭제"
          isFill={false}
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" isFill onClick={handleAddBtn} />
      </BtnContainer>
      {data && (
        <BannerTabel
          data={data}
          selectTable={selectTable}
          setSelectTable={setSelectTable}
          page={selectPage}
          setPage={setSelectPage}
        />
      )}
    </>
  );
}
