import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { delManager } from "service/api/login/getApi";
import useSWR from "swr";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { ManagerTable } from "./managerTable";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
`;

export function ManagerPage() {
  const navigate = useNavigate();
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const [selectPage, setSelectPage] = useState<number>(1);

  const { data, mutate } = useSWR(
    `/admin/manager?limit=30&offset=${(selectPage - 1) * 30}`,
  );

  const handleRemoveBtn = async () => {
    const res = await delManager(selectTable.join(";") + ";");
    mutate();
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.user.manager}/add`);
  };
  return (
    <Cover>
      <HeadText label="운영자 관리" />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="선택 삭제"
          onClick={handleRemoveBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <ManagerTable
        data={data?.data}
        count={data?.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
