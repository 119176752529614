import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { ReactComponent as ProfileIcon } from "asset/icon/community/profileIcon.svg";
import { ReactComponent as StarIcon } from "asset/icon/community/starIcon.svg";
import styleConstant from "service/constant/styleConstant";

interface Props {
  name: string;
  starScore: number;
  createdAt: string;
  lectureName: string;
  review: string;
  thumbnail: string;
  profileImg?: string | null;
}

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
  width: 878px;
  padding: 17px 24px;
  border: 1px solid ${styleConstant.COLOR.line_2};
  border-radius: 12px;
  cursor: pointer;
  .container {
    width: calc(100% - 222px);
    padding: 7px 0;
    .lecture {
      margin: 18px 0 6px;
      line-height: 24px;
      color: ${styleConstant.COLOR.gray_1};
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  .date {
    margin: 0;
    line-height: 18px;
    color: ${styleConstant.COLOR.gray_4};
    font-size: 12px;
    font-weight: 400;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    border-radius: 16px;
  }
  .info {
    p {
      margin: 0 0 2px;
      line-height: 18px;
      color: ${styleConstant.COLOR.gray_3};
      font-size: 12px;
      font-weight: 500;
    }
    .star {
      display: flex;
      align-items: center;
      .good {
        path {
          fill: #feb72d;
        }
      }
    }
  }
`;

const Review = styled.textarea`
  width: 100%;
  height: 24px;
  padding: 0;
  resize: none;
  border: none;
  outline: none;
  line-height: 24px;
  color: ${styleConstant.COLOR.gray_2};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

export function ReviewCard({
  name,
  starScore,
  createdAt,
  lectureName,
  review,
  thumbnail,
  profileImg,
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (review && textareaRef.current !== null) {
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [review]);

  return (
    <Card>
      <div className="container">
        <Text>
          <Profile>
            {profileImg ? (
              <img src={profileImg} width={32} height={32} alt="user profile" />
            ) : (
              <ProfileIcon />
            )}
            <div className="info">
              <p>{name}</p>
              <div className="star">
                <StarIcon className={0 < starScore ? "good" : ""} />
                <StarIcon className={1 < starScore ? "good" : ""} />
                <StarIcon className={2 < starScore ? "good" : ""} />
                <StarIcon className={3 < starScore ? "good" : ""} />
                <StarIcon className={4 < starScore ? "good" : ""} />
              </div>
            </div>
          </Profile>
          <p className="date">{moment(createdAt).format("YYYY.MM.DD")}</p>
        </Text>
        <p className="lecture">{lectureName}</p>
        <Review ref={textareaRef} value={review} readOnly />
      </div>
      <img src={thumbnail} width={180} height={117} alt="lecture thumbnail" />
    </Card>
  );
}
