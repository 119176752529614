import styled from "styled-components";

interface Props {
  label: string;
  type: "button" | "submit" | "reset";
}

const Btn = styled.button`
  width: 100%;
  height: 56px;
  border: none;
  background-color: #b2b2b2;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`;

export function LoginBtn({ label, type }: Props) {
  return <Btn type={type}>{label}</Btn>;
}
