import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { delTerm } from "service/api/terms";
import { HeadText } from "components/header";
import useSWR from "swr";
import { ListTable } from "./listTable";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0 26px;
`;

export function TermsPage() {
  const navigate = useNavigate();
  const [selectPage, setSelectPage] = useState<number>(1);
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const { data, mutate } = useSWR("/admin/term");

  const handleDeleteBtn = async () => {
    const res = await delTerm(selectTable.join(";") + ";");
    mutate();
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.terms}/add`);
  };

  return (
    <Cover>
      <HeadText label="약관 관리" />
      <BtnContainer>
        <DetailActionBtn
          label="선택 삭제"
          isFill={false}
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <ListTable
        data={data?.data}
        count={data?.count}
        page={selectPage}
        setPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
