import {
  MemberPage,
  MemberDetailPage,
  ManagerPage,
  ManagerDetailPage,
  ManagerAddPage,
  VideoPage,
  VideoDetailPage,
  ProblemPage,
  ProbolemDetailPage,
  BannerPage,
  BannerDetailPage,
  RoadmapPage,
  RoadmapDetailPage,
  CategoryPage,
  CategoryDetailPage,
  PopularPage,
  PricePage,
  MembershipListPage,
  QnaPage,
  QnaDetailPage,
  StudyPage,
  StudyDetailPage,
  KnowledgePage,
  KnowledgeDetailPage,
  ReviewPage,
  ReviewDetailPage,
  InformPage,
  InformDetailPage,
  TermsPage,
  TermsDetailPage,
} from "pages";
import routerConstant from "service/constant/routerConstant";
import LectureList from "../../pages/contents/Lecture_";
import LectureDetail from "../../pages/contents/Lecture_/Detail";

export const privateRoute = [
  {
    path: routerConstant.user.member,
    Component: <MemberPage />,
  },
  {
    path: `${routerConstant.user.member}/:id`,
    Component: <MemberDetailPage />,
  },
  {
    path: routerConstant.user.manager,
    Component: <ManagerPage />,
  },
  {
    path: `${routerConstant.user.manager}/:id`,
    Component: <ManagerDetailPage />,
  },
  {
    path: `${routerConstant.user.manager}/add`,
    Component: <ManagerAddPage />,
  },
  {
    path: routerConstant.contents.lecture,
    Component: <LectureList />,
  },
  {
    path: `${routerConstant.contents.lecture}/:id`,
    Component: <LectureDetail />,
  },
  {
    path: routerConstant.contents.video,
    Component: <VideoPage />,
  },
  {
    path: `${routerConstant.contents.video}/:id`,
    Component: <VideoDetailPage />,
  },
  {
    path: routerConstant.contents.problem,
    Component: <ProblemPage />,
  },
  {
    path: `${routerConstant.contents.problem}/:id`,
    Component: <ProbolemDetailPage />,
  },
  {
    path: routerConstant.contents.banner,
    Component: <BannerPage />,
  },
  {
    path: `${routerConstant.contents.banner}/:id`,
    Component: <BannerDetailPage />,
  },
  {
    path: routerConstant.contents.roadmap,
    Component: <RoadmapPage />,
  },
  {
    path: `${routerConstant.contents.roadmap}/:id`,
    Component: <RoadmapDetailPage />,
  },
  {
    path: routerConstant.contents.category,
    Component: <CategoryPage />,
  },
  {
    path: `${routerConstant.contents.category}/:id`,
    Component: <CategoryDetailPage />,
  },
  {
    path: routerConstant.contents.popularLecture,
    Component: <PopularPage />,
  },
  {
    path: routerConstant.membership.price,
    Component: <PricePage />,
  },
  {
    path: routerConstant.membership.list,
    Component: <MembershipListPage />,
  },
  {
    path: routerConstant.community.qna,
    Component: <QnaPage />,
  },
  {
    path: `${routerConstant.community.qna}/:id`,
    Component: <QnaDetailPage />,
  },
  {
    path: routerConstant.community.study,
    Component: <StudyPage />,
  },
  {
    path: `${routerConstant.community.study}/:id`,
    Component: <StudyDetailPage />,
  },
  {
    path: routerConstant.community.knowledge,
    Component: <KnowledgePage />,
  },
  {
    path: `${routerConstant.community.knowledge}/:id`,
    Component: <KnowledgeDetailPage />,
  },
  {
    path: routerConstant.community.review,
    Component: <ReviewPage />,
  },
  {
    path: `${routerConstant.community.review}/:id`,
    Component: <ReviewDetailPage />,
  },
  {
    path: routerConstant.community.inform,
    Component: <InformPage />,
  },
  {
    path: `${routerConstant.community.inform}/:id`,
    Component: <InformDetailPage />,
  },
  {
    path: routerConstant.terms,
    Component: <TermsPage />,
  },
  {
    path: `${routerConstant.terms}/:id`,
    Component: <TermsDetailPage />,
  },
];
