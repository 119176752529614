import React from "react";
import styled from "styled-components";
import { Table } from "antd";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
  selectLecture: any;
  setSelectLecture: React.Dispatch<React.SetStateAction<any>>;
}

const CustomTable = styled(Table)``;

export function LectureModalTable({
  data,
  count,
  selectPage,
  setSelectPage,
  selectLecture,
  setSelectLecture,
}: Props) {
  const columns = [
    {
      title: "강의 ID",
      dataIndex: "id",
    },
    {
      title: "카테고리",
      dataIndex: "Category",
      render: (category: any) => <>{category.name}</>,
    },
    {
      title: "강의명",
      dataIndex: "title",
    },
    {
      title: "강사명",
      dataIndex: "teacher",
    },
  ];

  const handleSelectTableChange = (_: any, selectItem: any) => {
    setSelectLecture(selectItem);
  };

  const rowSelection: any = {
    type: "radio",
    selectedRowKeys: selectLecture.length !== 0 && [selectLecture[0]?.id],
    onChange: handleSelectTableChange,
  };

  return (
    <CustomTable
      rowKey="id"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={{
        current: selectPage,
        pageSize: 5,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
