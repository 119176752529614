import React from "react";
import styled from "styled-components";
import { UseFormRegisterReturn } from "react-hook-form";
import styleConstant from "service/constant/styleConstant";

interface Props {
  isError?: boolean;
  register?: UseFormRegisterReturn;
  [key: string]: any;
}

const Input = styled.input<{ isError: boolean }>`
  width: 100%;
  height: 56px;
  padding: 18px 16px;
  border: ${({ isError }) =>
    isError
      ? `1px solid ${styleConstant.COLOR.error}`
      : `1px solid ${styleConstant.COLOR.line_1}`};
  outline: none;
  color: ${styleConstant.COLOR.gray_1};
  font-size: 16px;
  &::placeholder {
    color: ${styleConstant.COLOR.gray_4};
  }
`;

export function LoginInput({ isError = false, register, ...rest }: Props) {
  return <Input isError={isError} {...register} {...rest} />;
}
