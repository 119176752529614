import React, { useState } from "react";
import styled from "styled-components";
import { TableInput } from "components/input";
import type { IChangeData } from "./detail";

interface Props {
  data: IChangeData;
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
}

const Cover = styled.div`
  margin: 28px 0;
  .tableTitle {
    margin: 0 0 22px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
`;

export function VideoDetailTable({ data, setData }: Props) {
  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleContentsKeyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      content_id: e.target.value,
    }));
  };

  return (
    <Cover>
      <p className="tableTitle">기본정보 *</p>
      <Table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <TableInput value={data.title} onChange={handleTitleInput} />
            </td>
          </tr>
          <tr>
            <th>미디어 컨텐츠 키</th>
            <td>
              <TableInput
                value={data.content_id}
                onChange={handleContentsKeyInput}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
