import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ProfileIcon } from "asset/icon/community/profileIcon.svg";
import { UserLevel } from "./userLevel";
import styleConstant from "service/constant/styleConstant";
import moment from "moment";
import { ViewEditor } from "components/editor";

interface Props {
  name: string | undefined;
  level: number;
  createdAt: string;
  profileImg?: string;
  content: string;
}

const Card = styled.div`
  width: 780px;
  padding: 24px;
  border: 1px solid ${styleConstant.COLOR.line_2};
  background-color: #fff;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${styleConstant.COLOR.line_2};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  svg {
    transform: translate(10px, 0) scale(1.75);
    margin-right: 18px;
  }
  img {
    border-radius: 23px;
  }
  div {
    p:first-child {
      display: flex;
      align-items: center;
      gap: 3px;
      margin: 0 0 2px;
      line-height: 22px;
      color: ${styleConstant.COLOR.gray_1};
      font-size: 14px;
      font-weight: 700;
      span {
        margin-top: 6px;
      }
    }
    p:nth-child(2) {
      margin: 0;
      line-height: 18px;
      color: ${styleConstant.COLOR.gray_3};
      font-size: 12px;
    }
  }
`;

export function QnAAnswerCard({
  name,
  createdAt,
  level,
  profileImg,
  content,
}: Props) {
  return (
    <Card>
      <Head>
        <Info>
          {profileImg ? (
            <img src={profileImg} width={42} height={42} alt="user profile" />
          ) : (
            <ProfileIcon />
          )}
          <div>
            <p>
              {name}
              <span>
                <UserLevel level={level} />
              </span>
            </p>
            <p>{moment(createdAt).format("YYYY.MM.DD")}</p>
          </div>
        </Info>
      </Head>
      <ViewEditor value={content} />
    </Card>
  );
}
