import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { delProblem } from "service/api/content/getApi";
import { HeadText } from "components/header";
import { SearchTable } from "components/table";
import { ProblemTable } from "./problemTable";
import { DetailActionBtn } from "components/button";
import { IProbleList } from "service/interface/problem";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 33px 0 25px;
`;

const testData = [{ id: 999, title: "두 수의 합", language: "C, C++, Java" }];

export function ProblemPage() {
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [submitSearch, setSubmitSearch] = useState<string>("");
  const [selectPage, setSelectPage] = useState<number>(1);
  const [selectTable, setSelectTable] = useState<string[]>([]);

  const { data, mutate } = useSWR(
    `/admin/problem?limit=30&offset=${
      (selectPage - 1) * 30
    }&search=${submitSearch}`,
  );

  useEffect(() => {
    if (searchInputValue.length === 0) {
      setSubmitSearch("");
    }
  }, [searchInputValue]);

  const handleRemoveBtn = async () => {
    const res = await delProblem(selectTable.join(";") + ";");
    mutate();
  };

  const handleSearchBtn = () => {
    setSubmitSearch(searchInputValue);
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.problem}/add`);
  };
  return (
    <Cover>
      <HeadText label="문제" margin="0 0 25px" />
      <SearchTable
        rightTitle="검색어"
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        onClick={handleSearchBtn}
      />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="선택 삭제"
          onClick={handleRemoveBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <ProblemTable
        data={data?.data}
        total={data?.count}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
        page={selectPage}
        setPage={setSelectPage}
      />
    </Cover>
  );
}
