import React from "react";
import styled from "styled-components";
import type { IData } from "./index";

interface Props {
  unit?: string;
  isDisabled?: boolean;
  label: string;
  value: number;
  name?: "amount" | "discountAmount";
  setValue?: React.Dispatch<React.SetStateAction<IData>>;
}

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
    color: #111827;
    font-size: 14px;
  }
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 188px;
    height: 52px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    input {
      width: 155px;
      height: 100%;
      padding: 16px;
      border: none;
      border-radius: 4px;
      outline: none;
      &[type="number"]::-webkit-outer-spin-button,
      &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    input:disabled {
      background-color: #fff;
    }
  }
`;

export function PriceInput({
  unit = "원",
  name = "amount",
  label,
  isDisabled,
  value,
  setValue,
}: Props) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue &&
      setValue((prev) => ({
        ...prev,
        [name]: Number(e.target.value.replace(/[^0-9]/g, "")),
      }));
  };

  return (
    <Price>
      <p>{label}</p>
      <div>
        <input
          disabled={isDisabled}
          type="string"
          value={value}
          onChange={handleInputChange}
        />
        <p>{unit}</p>
      </div>
    </Price>
  );
}
