import AxiosManager from "../_axiosManager";

export async function delQuestion(id: string) {
  const res = await AxiosManager.getInstance().delete(`/admin/question/${id}`);

  return res;
}

export async function delStudy(id: string) {
  const res = await AxiosManager.getInstance().delete(`/admin/study/${id}`);

  return res;
}

export async function delKnowledge(id: string) {
  const res = await AxiosManager.getInstance().delete(`/admin/knowledge/${id}`);

  return res;
}

export async function getInformDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/inform/${id}`);

  return res;
}

export async function postInform(body: any) {
  const res = await AxiosManager.getInstance().post("/inform", body);

  return res;
}

export async function putInform(id: string, body: any) {
  const res = await AxiosManager.getInstance().put(`/admin/inform/${id}`, body);

  return res;
}

export async function delInform(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/inform?ids=${ids}`,
  );

  return res;
}

export async function delReview(id: string) {
  const res = await AxiosManager.getInstance().delete(`/admin/review/${id}`);

  return res;
}
