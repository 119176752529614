import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { putMembershipPrice } from "service/api/membership/post";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { PriceInput } from "./priceInput";
import useSWR from "swr";
import { DetailActionBtn } from "components/button";

export interface IData {
  amount: number;
  discountAmount: number;
}

const Cover = styled.div``;

const PriceTable = styled.table`
  width: 100%;
  margin: 35px 0 23px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 76px;
    padding: 16px 24px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    display: flex;
    align-items: center;
    gap: 65px;
    height: 76px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function PricePage() {
  const [oneData, setOneData] = useState<IData>({
    amount: 0,
    discountAmount: 0,
  });
  const [twoData, setTwoData] = useState<IData>({
    amount: 0,
    discountAmount: 0,
  });
  const [threeData, setThreeData] = useState<IData>({
    amount: 0,
    discountAmount: 0,
  });
  const [halfYearData, setHalfYearData] = useState<IData>({
    amount: 0,
    discountAmount: 0,
  });
  const [yearData, setYearData] = useState<IData>({
    amount: 0,
    discountAmount: 0,
  });
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const { data, mutate } = useSWR("/admin/membership");

  useEffect(() => {
    if (data) {
      data.data.forEach((list: any) => {
        if (list.month === 1) {
          setOneData(list);
        } else if (list.month === 2) {
          setTwoData(list);
        } else if (list.month === 3) {
          setThreeData(list);
        } else if (list.month === 6) {
          setHalfYearData(list);
        } else if (list.month === 12) {
          setYearData(list);
        }
      });
    }
  }, [data]);

  const handleSaveBtn = async () => {
    const res = await putMembershipPrice([
      {
        month: 1,
        ...oneData,
      },
      {
        month: 2,
        ...twoData,
      },
      {
        month: 3,
        ...threeData,
      },
      {
        month: 6,
        ...halfYearData,
      },
      {
        month: 12,
        ...yearData,
      },
    ]);
    if (res.status === 200) {
      setIsSuccessModalVisible(true);
    } else {
      window.alert("항목을 입력해주세요");
    }
  };

  const handleSuccessBtn = () => {
    mutate();
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <HeadText label="멤버쉽 가격" />
      <PriceTable>
        <tr>
          <th>1개월</th>
          <td>
            <PriceInput
              label="정상가"
              value={oneData.amount}
              name="amount"
              setValue={setOneData}
            />
            <PriceInput
              label="할인가"
              value={oneData.discountAmount}
              name="discountAmount"
              setValue={setOneData}
            />
          </td>
        </tr>
        <tr>
          <th>2개월</th>
          <td>
            <PriceInput
              label="정상가"
              value={twoData.amount}
              name="amount"
              setValue={setTwoData}
            />
            <PriceInput
              label="할인가"
              value={twoData.discountAmount}
              name="discountAmount"
              setValue={setTwoData}
            />
            <PriceInput
              label="할인%"
              unit="%"
              isDisabled
              value={Math.ceil(
                100 - (twoData.discountAmount / twoData.amount) * 100,
              )}
            />
          </td>
        </tr>
        <tr>
          <th>3개월</th>
          <td>
            <PriceInput
              label="정상가"
              value={threeData.amount}
              name="amount"
              setValue={setThreeData}
            />
            <PriceInput
              label="할인가"
              value={threeData.discountAmount}
              name="discountAmount"
              setValue={setThreeData}
            />
            <PriceInput
              label="할인%"
              unit="%"
              isDisabled
              value={Math.ceil(
                100 - (threeData.discountAmount / threeData.amount) * 100,
              )}
            />
          </td>
        </tr>
        <tr>
          <th>6개월</th>
          <td>
            <PriceInput
              label="정상가"
              value={halfYearData.amount}
              name="amount"
              setValue={setHalfYearData}
            />
            <PriceInput
              label="할인가"
              value={halfYearData.discountAmount}
              name="discountAmount"
              setValue={setHalfYearData}
            />
            <PriceInput
              label="할인%"
              unit="%"
              isDisabled
              value={Math.ceil(
                100 - (halfYearData.discountAmount / halfYearData.amount) * 100,
              )}
            />
          </td>
        </tr>
        <tr>
          <th>12개월</th>
          <td>
            <PriceInput
              label="정상가"
              value={yearData.amount}
              name="amount"
              setValue={setYearData}
            />
            <PriceInput
              label="할인가"
              value={yearData.discountAmount}
              name="discountAmount"
              setValue={setYearData}
            />
            <PriceInput
              label="할인%"
              unit="%"
              isDisabled
              value={Math.ceil(
                100 - (yearData.discountAmount / yearData.amount) * 100,
              )}
            />
          </td>
        </tr>
      </PriceTable>
      <BtnContainer>
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
