import React from "react";
import styled from "styled-components";
import { Table } from "antd";

interface Props {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .delete {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    width: 98px;
    height: 40px;
    border: 1px solid #e3e3e3;
    outline: none;
    border-radius: 4px;
    padding: 16px;
    font-size: 14px;
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export function LectureTable({ data, setData }: Props) {
  const handleDeleteBtn = (idx: number) => {
    let copiedArr = [...data];
    const deletedArr = copiedArr.filter((list, listIdx) => listIdx !== idx);
    setData((prev: any) => ({
      ...prev,
      LoadMapCourses: deletedArr,
    }));
  };

  const handleOrderInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    let copiedArr = [...data];
    copiedArr[idx].order = e.target.value;
    setData((prev: any) => ({
      ...prev,
      LoadMapCourses: copiedArr,
    }));
  };

  const columns = [
    {
      title: "NO",
      dataIndex: "id",
      width: "7%",
      render: (id: any, value: any, index: any) => <>{index + 1}</>,
    },
    {
      title: "강의ID",
      dataIndex: "LectureId",
      width: "11%",
    },
    {
      title: "카테고리",
      dataIndex: "Lecture",
      width: "12%",
      render: (lecture: any) => <>{lecture?.Category.name}</>,
    },
    {
      title: "강의명",
      dataIndex: "title",
      width: "40%",
    },
    {
      title: "강사명",
      dataIndex: "Lecture",
      width: "11%",
      render: (lecture: any) => <>{lecture?.teacher}</>,
    },
    {
      title: "순서",
      dataIndex: "order",
      render: (text: number, _: any, idx: number) => (
        <input
          type="number"
          value={text}
          onChange={(e) => handleOrderInput(e, idx)}
        />
      ),
    },
    {
      title: "-",
      dataIndex: "id",
      render: (id: number, _: any, index: number) => (
        <span className="delete" onClick={() => handleDeleteBtn(index)}>
          삭제
        </span>
      ),
    },
  ];

  return <CustomTable columns={columns} dataSource={data} />;
}
