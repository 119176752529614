import React from "react";
import styled from "styled-components";
import { Table } from "antd";
import { KeyedMutator } from "swr";
import { delPopularLecture } from "service/api/content/getApi";

interface Props {
  data: any;
  mutate: KeyedMutator<any>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .delete {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function ListTable({ data, mutate }: Props) {
  const handleDeleteBtn = async (id: number) => {
    const res = await delPopularLecture(id + "");
    mutate();
  };
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      render: (id: number, _: any, idx: number) => <>{idx + 1}</>,
      width: "7%",
    },
    {
      title: "강의 Id",
      dataIndex: "id",
      width: "13%",
    },
    {
      title: "카테고리",
      dataIndex: "LectureCategory",
      width: "15%",
    },
    {
      title: "강의명",
      dataIndex: "title",
      width: "48%",
    },
    {
      title: "강사명",
      dataIndex: "teacher",
      width: "10%",
    },
    {
      title: "-",
      dataIndex: "id",
      render: (id: number) => (
        <span className="delete" onClick={() => handleDeleteBtn(id)}>
          삭제
        </span>
      ),
    },
  ];
  return <CustomTable columns={columns} dataSource={data} />;
}
