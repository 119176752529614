import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { getTermDetail, postTerm, putTerm } from "service/api/terms";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { TableInput } from "components/input";
import { TypeDropdown } from "./typeDropdown";
import Editor from "components/editor";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const Table = styled.table`
  width: 100%;
  margin: 35px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .inputCard {
    display: flex;
    gap: 4px;
    height: 76px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  textarea {
    width: 100%;
    height: 379px;
    padding: 16px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    outline: none;
    resize: none;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin: 52px 0 0;
`;

export function TermsDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState<string>("");
  const [typeValue, setTypeValue] = useState<number>(0);
  const [contentValue, setContentValue] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getTermDetail(params.id + "");
        if (res.status === 200) {
          setTitleValue(res.data.data.title);
          setContentValue(res.data.data.content);
          setTypeValue(res.data.data.type);
          setIsUpdated(true);
        }
      })();
    }
  }, [params]);

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  const handleContentInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentValue(e.target.value);
  };

  const handleCancelBtn = () => {
    navigate(routerConstant.terms);
  };

  const handleSaveBtn = async () => {
    if (titleValue && contentValue && typeValue) {
      if (params.id === "add") {
        const res = await postTerm({
          title: titleValue,
          content: contentValue,
          type: typeValue,
        });
        if (res.status === 201) {
          setIsSuccessModalVisible(true);
        }
      } else {
        const res = await putTerm(params.id + "", {
          title: titleValue,
          content: contentValue,
          type: typeValue,
        });
        if (res.status === 200) {
          setIsSuccessModalVisible(true);
        }
      }
    } else {
      window.alert("항목을 입력해 주세요");
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.terms);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <HeadText label="약관 상세" />
      <Table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <TableInput value={titleValue} onChange={handleTitleInput} />
            </td>
            <th>구분</th>
            <td>
              <TypeDropdown
                typeList={[
                  { name: "이용약관", id: 1 },
                  { name: "개인정보 처리방침", id: 2 },
                  { name: "보호자 동의약관", id: 3 },
                  { name: "정기 결제 약관", id: 4 },
                  { name: "환불 규정", id: 5 },
                  { name: "결제사 정보 제공", id: 6 },
                ]}
                type={typeValue}
                setType={setTypeValue}
              />
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan={3}>
              {!isUpdated && (
                <Editor
                  setValue={setContentValue}
                  height="379px"
                  margin="0 0 43px"
                />
              )}
              {isUpdated && (
                <Editor
                  value={contentValue}
                  setValue={setContentValue}
                  height="379px"
                  margin="0 0 43px"
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
