import AxiosManager from "../_axiosManager";

export async function postLogin(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/login", body);

  return res;
}

export async function postJoin(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/join", body);

  return res;
}

export async function putUserDetail(userId: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/user/${userId}`,
    body,
  );

  return res;
}

export async function putManagerDetail(managerId: string, body: any) {
  const res = await AxiosManager.getInstance().put(
    `/admin/manager/${managerId}`,
    body,
  );

  return res;
}
