import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import routerConstant from "service/constant/routerConstant";
import type { IVideoList } from "service/interface/videos";

interface Props {
  data: IVideoList[];
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
  selectTable: string[];
  setSelectTable: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function VideoTable({
  data,
  count,
  selectPage,
  setSelectPage,
  selectTable,
  setSelectTable,
}: Props) {
  const navigate = useNavigate();
  const handleDetailBtn = (id: string) => {
    navigate(`${routerConstant.contents.video}/${id}`);
  };
  const columns = [
    {
      title: "NO",
      dataIndex: "id",
    },
    {
      title: "영상명",
      dataIndex: "title",
    },
    {
      title: "상세보기",
      dataIndex: "id",
      width: "13%",
      render: (text: number) => (
        <span className="routerBtn" onClick={() => handleDetailBtn(text + "")}>
          바로가기
        </span>
      ),
    },
  ];

  const handleSelectTableChange = (selectTable: any) => {
    setSelectTable(selectTable);
  };

  const rowSelection: any = {
    selectedRowKeys: selectTable,
    onChange: handleSelectTableChange,
  };

  return (
    <CustomTable
      rowKey="id"
      rowSelection={rowSelection}
      dataSource={data}
      columns={columns}
      pagination={{
        current: selectPage,
        pageSize: 30,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
