import React from "react";
import styled from "styled-components";
import {postImgUpload} from "service/api/common"
import {TableInput, ImageUploadInput} from "components/input"
import type {IBannerDetail} from "../banner.interface"

interface Props {
    data: IBannerDetail
    setData: React.Dispatch<React.SetStateAction<IBannerDetail>>
}

const Table = styled.table`
  width: 100%;
  margin: 51px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 75px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 913;
    height: 75px;
    margin: 0;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .length {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    p {
        margin: 0;
        text-decoration: underline;
        font-size: 14px;
    }
  }
  .imgContainer {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    min-height: 75px;
    height: fit-content;
    img {
        width: 328px;
        max-height: 154px;
    }
  }
`

export function BannerDetailTable({data, setData}: Props) {
    const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prev => ({
            ...prev,
            title: e.target.value
        }))
    }

    const handleContentInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prev => ({
            ...prev,
            subTitle: e.target.value
        }))
    }

    const handleLinkInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prev => ({
            ...prev,
            link: e.target.value
        }))
    }

    const handleImgInput = async(e: any) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append("image", e.target.files[0]);
            const res = await postImgUpload(formData);
            if(res.status === 200) {
            setData((prev) => ({
              ...prev,
              src: res.data.data.src
            }));}
          }
    }
    return (
        <Table>
            <tbody>
                <tr>
                    <th>제목</th>
                    <td className="length"><TableInput value={data.title} onChange={handleTitleInput} maxLength={24} /><p>{data.title.length}/24</p></td>
                </tr>
                <tr>
                <th>내용</th>
                    <td className="length"><TableInput value={data.subTitle} onChange={handleContentInput} maxLength={40} /><p>{data.subTitle.length}/40</p></td>
                </tr>
                <tr>
                <th>배너 이미지*</th>
                <td className="imgContainer">{data.src && <img src={data.src} alt={"banner"} />}<ImageUploadInput onChange={handleImgInput} /></td>
                </tr>
                <tr>
                    <th>링크 URL*</th>
                    <td><div style={{width: "50%"}}><TableInput value={data.link} onChange={handleLinkInput} placeholder="http://" /></div></td>
                </tr>
            </tbody>
        </Table>
    )
}