import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getLectureCategory } from "service/api/content/getApi";
import { TableInput } from "components/input";
import { ChangeTableDropdown } from "components/dropdown";
import { ImageUploadInput } from "components/input";
import type { IChangeData } from "./index";
import { CloseOutlined } from "@ant-design/icons";
import { postImgUpload } from "service/api/common";
import { CategoryDropdown } from "pages/contents/problem/categoryDropdown";

interface Props {
  data: IChangeData;
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
}

const selectLevelDropdown: { [key: string]: any } = {
  "1": "초급",
  "2": "중급",
  "3": "고급",
};

const selectLanguageDropdown: { [key: string]: any } = {
  "1": "C",
  "2": "C++",
  "3": "Java",
  "4": "Python",
};

const Cover = styled.div`
  margin: 28px 0 36px;
  .tableTitle {
    margin: 0 0 10px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .input {
    height: 75px;
  }
  .thumbnail {
    height: 75px;
    td {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      width: 254.7%;
      height: fit-content;
      min-height: 75px;
      img {
        max-width: 217px;
        max-height: 131px;
      }
    }
  }
  .textarea {
    height: 127px;
  }
  .tag {
    height: 85px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 22px;
  label {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 101px;
  padding: 8px 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  resize: none;
  outline: none;
  line-height: 175%;
  color: #111827;
  font-size: 16px;
  &::placeholder {
    color: #8a92a6;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 911px;
  min-height: 62px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 30px;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: rgba(176, 179, 188, 0.1);
  color: #4a4a4a;
  font-size: 14px;
`;

const TagInput = styled.input`
  height: 30px;
  border: none;
  outline: none;
  font-size: 14px;
  &::placeholder {
    color: #8a92a6;
  }
`;

export function DetailTable({ data, setData }: Props) {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getLectureCategory();
      setCategoryList(res.data.data);
    })();
  }, []);
  const tagInputRef = useRef<HTMLInputElement>(null);

  const handleIsActiveInputChange = (status: boolean) => {
    setData((prev) => ({
      ...prev,
      isActive: status,
    }));
  };

  const handleChangeTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleChangeTeacherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      teacher: e.target.value,
    }));
  };

  const handleChangeDescriptionInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setData((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const handleTagContainerClick = () => {
    tagInputRef.current!.focus();
  };

  useEffect(() => {
    tagInputRef.current!.value = "";
  }, [data.tag]);

  const handleTagDeleteBtn = (idx: number) => {
    const deleteArr = data.tag.split(";").slice(0, -1);
    deleteArr.splice(idx, 1);
    setData((prev) => ({
      ...prev,
      tag: deleteArr.length === 0 ? "" : `${deleteArr.join(";")};`,
    }));
  };

  const handleTageInput = (e: any) => {
    if (e.keyCode === 13 && e.target.value.length !== 0) {
      setData((prev) => ({
        ...prev,
        tag: prev.tag ? `${prev.tag}${e.target.value};` : `${e.target.value};`,
      }));
    }
  };

  const handleLevelDropdownChange = (label: any) => {
    switch (label) {
      case "초급":
        setData((prev) => ({
          ...prev,
          level: 1,
        }));
        break;
      case "중급":
        setData((prev) => ({
          ...prev,
          level: 2,
        }));
        break;
      case "고급":
        setData((prev) => ({
          ...prev,
          level: 3,
        }));
        break;
    }
  };

  const handleLanguageDropdownChange = (label: any) => {
    switch (label) {
      case "C":
        setData((prev) => ({
          ...prev,
          language: 1,
        }));
        break;
      case "C++":
        setData((prev) => ({
          ...prev,
          language: 2,
        }));
        break;
      case "Java":
        setData((prev) => ({
          ...prev,
          language: 3,
        }));
        break;
      case "Python":
        setData((prev) => ({
          ...prev,
          language: 4,
        }));
        break;
    }
  };

  const handleImgInput = async (e: any) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const res = await postImgUpload(formData);
      if (res.status === 200) {
        setData((prev) => ({
          ...prev,
          thumbnail: res.data.data.src,
        }));
      }
    }
  };

  return (
    <Cover>
      <p className="tableTitle">기본정보</p>
      <Table>
        <tbody>
          <tr className="input">
            <th>공개여부</th>
            <td>
              <StatusContainer>
                <label htmlFor="active">
                  <input
                    type="radio"
                    name="isActive"
                    id="active"
                    checked={data.isActive}
                    onChange={() => handleIsActiveInputChange(true)}
                  />
                  공개
                </label>
                <label htmlFor="nonActive">
                  <input
                    type="radio"
                    name="isActive"
                    id="nonActive"
                    checked={!data.isActive}
                    onChange={() => handleIsActiveInputChange(false)}
                  />
                  비공개
                </label>
              </StatusContainer>
            </td>
            <th>카테고리</th>
            <td>
              <CategoryDropdown
                categoryList={categoryList}
                category={data.CategoryId}
                name="CategoryId"
                setCategory={setData}
              />
            </td>
          </tr>
          <tr className="input">
            <th>강의명</th>
            <td>
              <TableInput
                placeholder="강의명 입력"
                value={data.title}
                onChange={handleChangeTitleInput}
              />
            </td>
            <th>강사명</th>
            <td>
              <TableInput
                placeholder="강사명 입력"
                value={data.teacher}
                onChange={handleChangeTeacherInput}
              />
            </td>
          </tr>
          <tr className="thumbnail">
            <th>강의 썸네일</th>
            <td colSpan={3}>
              {data.thumbnail && <img src={data.thumbnail} alt="thumbnail" />}
              <ImageUploadInput onChange={handleImgInput} />
            </td>
          </tr>
          <tr className="input">
            <th>난이도</th>
            <td>
              <ChangeTableDropdown
                categoryList={["초급", "중급", "고급"]}
                category={selectLevelDropdown[data.level + ""]}
                onChange={handleLevelDropdownChange}
              />
            </td>
            <th>사용언어</th>
            <td>
              <ChangeTableDropdown
                categoryList={["C", "C++", "Java", "Python"]}
                category={selectLanguageDropdown[data.language]}
                onChange={handleLanguageDropdownChange}
              />
            </td>
          </tr>
          <tr className="textarea">
            <th>썸네일 소개글</th>
            <td colSpan={3}>
              <Textarea
                placeholder="소개글 입력"
                value={data.description}
                onChange={handleChangeDescriptionInput}
              />
            </td>
          </tr>
          <tr className="tag">
            <th>태그</th>
            <td colSpan={3} className="tagTd">
              <TagContainer onClick={handleTagContainerClick}>
                {data.tag
                  ?.split(";")
                  .slice(0, -1)
                  .map((tag, idx) => (
                    <Tag>
                      {tag}
                      <CloseOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTagDeleteBtn(idx)}
                      />
                    </Tag>
                  ))}
                <TagInput
                  placeholder="태그 입력 후 enter"
                  ref={tagInputRef}
                  onKeyDown={handleTageInput}
                />
              </TagContainer>
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
