import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import routerConstant from "service/constant/routerConstant";
import tokenConstant from "service/constant/tokenConstant";

export function LoginRoute({ children }: any) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(tokenConstant.ACCESS_TOKEN),
  );

  useEffect(() => {
    setAccessToken(localStorage.getItem(tokenConstant.ACCESS_TOKEN));
  }, []);

  return accessToken ? (
    <Navigate to={routerConstant.contents.lecture} />
  ) : (
    children
  );
}
