import React from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import styleConstant from "service/constant/styleConstant";
import { DownOutlined } from "@ant-design/icons";

interface Props {
  categoryList: string[];
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}

const MenuItem = styled.p`
  margin: 0;
  padding: 8px 4px;
  color: ${styleConstant.COLOR.gray_1};
  font-size: 14px;
  cursor: pointer;
`;

const DropdownBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  height: 51px;
  padding: 16px;
  color: #8a92a6;
  font-size: 16px;
  cursor: pointer;
`;

export function SearchCategoryDropdown({
  categoryList,
  category,
  setCategory,
}: Props) {
  const handleItemClick = (label: string) => {
    setCategory(label);
  };
  const menuItem = categoryList.map((label, idx) => ({
    label: <MenuItem onClick={() => handleItemClick(label)}>{label}</MenuItem>,
    key: idx,
  }));
  const menu = <Menu items={menuItem} />;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <DropdownBox>
        {category} <DownOutlined />
      </DropdownBox>
    </Dropdown>
  );
}
