import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import routerConstant from "service/constant/routerConstant";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
}

interface ILoginType {
  kakaoId: string | undefined;
  naverId: string | undefined;
  googleId: string | undefined;
}

const CustomTable = styled(Table)`
  margin: 44px 0 0;
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function MemberTable({ data, count, selectPage, setSelectPage }: Props) {
  const navigate = useNavigate();
  const handleDetailBtn = (id: string) => {
    navigate(`${routerConstant.user.member}/${id}`);
  };
  const loginType = ({ kakaoId, naverId, googleId }: ILoginType) => {
    if (kakaoId) {
      return "카카오";
    } else if (naverId) {
      return "네이버";
    } else if (googleId) {
      return "구글";
    } else {
      return "이메일";
    }
  };

  const colums = [
    {
      title: "NO",
      dataIndex: "id",
    },
    {
      title: "이메일",
      dataIndex: "email",
    },
    {
      title: "닉네임",
      dataIndex: "nickName",
    },
    {
      title: "핸드폰",
      dataIndex: "phoneNumber",
    },
    {
      title: "로그인방식",
      dataIndex: "loginType",
    },
    {
      title: "멤버쉽",
      dataIndex: "UserMemberShips",
      render: (membership: string) => <>{membership.length > 0 ? "Y" : "N"}</>,
    },
    {
      title: "가입일",
      dataIndex: "createdAt",
      render: (text: string) => <>{moment(text).format("YYYY.MM.DD")}</>,
    },
    {
      title: "회원상태",
      dataIndex: "isActive",
      render: (text: string) => <>{text ? "활성" : "비활성"}</>,
    },
    {
      title: "상세보기",
      dataIndex: "id",
      render: (text: number) => (
        <span className="routerBtn" onClick={() => handleDetailBtn(text + "")}>
          바로가기
        </span>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={data}
      columns={colums}
      pagination={{
        current: selectPage,
        pageSize: 30,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
