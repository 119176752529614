import AxiosManager from "../_axiosManager";

export async function delManager(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/manager?ids=${ids}`,
  );

  return res;
}

export async function getManagerDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/manager/${id}`);

  return res;
}
