import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Cover = styled.div`
input {
    display: none;
}
p {
    margin: 0;
    text-decoration: underline;
    line-height: 175%;
    color: #111827;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
`

export function ImageUploadInput({onChange}: Props) {
    const inputRef = useRef<HTMLInputElement>(null)

    const handleUploadBtn = () => {
        inputRef.current?.click()
    }
    return (
        <Cover>
            <input ref={inputRef} type="file" accept="image/*" onChange={onChange} />
            <p onClick={handleUploadBtn}>찾아보기</p>
        </Cover>
    )
}