import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { Modal } from "antd";
import { ReactComponent as CancelIcon } from "asset/icon/lecture/cancelIcon.svg";
import { LectureModalTable } from "./lectureModalTable";
import { DetailActionBtn } from "components/button";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: (lecture: any) => void;
}

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    .ant-modal-body {
      width: 100%;
      min-height: 604px;
      height: 100%;
      padding: 24px;
      .modalTitle {
        margin: 6px 0 22px;
        text-align: center;
        line-height: 28px;
        color: #171717;
        font-size: 20px;
        font-weight: 500;
      }
      svg {
        cursor: pointer;
      }
    }
  }
`;

const SearchTable = styled.table`
  width: 100%;
  margin: 0 0 17px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    height: 52px;
    padding: 0;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
    input {
      width: 100%;
      height: 100%;
      padding: 16px;
      border: none;
      outline: none;
      &::placeholder {
        color: #8a92a6;
      }
    }
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: 12px 0 0;
`;

export function LectureModal({ isVisible, setIsVisible, onClick }: Props) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageLength, setPageLength] = useState<number>(0);
  const [selectPage, setSelectPage] = useState<number>(1);
  const [selectLecture, setSelectLecture] = useState<any>([]);
  const [lectureData, setLectureData] = useState([]);
  const { data } = useSWR(
    `/admin/lecture?limit=5&offset=${
      (selectPage - 1) * 5
    }&search=${searchValue}&searchCategory=`,
  );

  useEffect(() => {
    if (data) {
      setPageLength(data.count);
      setLectureData(data.data);
    }
  }, [data]);

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleLectureAddBtn = () => {
    if (selectLecture.length !== 0) {
      onClick(selectLecture);
      setSelectLecture([]);
    } else {
      window.alert("강의를 선택해 주세요");
    }
  };

  return (
    <CustomModal
      visible={isVisible}
      maskClosable={true}
      width={746}
      centered={true}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <CancelIcon onClick={handleCancel} />
      <p className="modalTitle">강의 추가</p>
      <SearchTable>
        <tr>
          <th>검색어</th>
          <td>
            <input
              placeholder="강의 제목 검색어 입력"
              value={searchValue}
              onChange={handleSearchInput}
            />
          </td>
        </tr>
      </SearchTable>
      <LectureModalTable
        data={lectureData}
        count={pageLength}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectLecture={selectLecture}
        setSelectLecture={setSelectLecture}
      />
      <BtnContainer>
        <DetailActionBtn label="취소" isFill={false} onClick={handleCancel} />
        <DetailActionBtn label="확인" onClick={handleLectureAddBtn} />
      </BtnContainer>
    </CustomModal>
  );
}
