import React from "react";
import styleConstant from "service/constant/styleConstant";
import styled from "styled-components";
import { ViewEditor } from "components/editor";
import moment from "moment";
import { UserLevel } from "components/community";
import { ReactComponent as QIcon } from "asset/icon/community/qIcon.svg";
import { ReactComponent as ProfileIcon } from "asset/icon/community/profileIcon.svg";

interface Props {
  lectureTitle?: string;
  title: string;
  content: string;
  profileName: string;
  level: number;
  profileImg?: string;
  createdAt: string;
  viewCount: string;
}

const Container = styled.div`
  margin: 0 0 60px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    gap: 12px;
    p {
      margin: 0;
      line-height: 18px;
      color: ${styleConstant.COLOR.main_O};
      font-size: 14px;
    }
  }
`;

const Content = styled.div`
  .questionTitle {
    margin: 17px 0 24px;
    line-height: 32px;
    color: ${styleConstant.COLOR.gray_1};
    font-size: 22px;
    font-weight: 700;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 24px 0 0;
  img {
    min-width: 24px !important;
    border-radius: 12px;
  }
  div {
    height: 12px;
    border-left: 1px solid ${styleConstant.COLOR.line_1};
  }
  p:nth-child(2) {
    margin: 0;
    color: ${styleConstant.COLOR.gray_2};
    font-size: 12px;
  }
  p:nth-child(5),
  p:nth-child(7) {
    margin: 0;
    color: ${styleConstant.COLOR.gray_3};
    font-size: 12px;
  }
`;

export function QuestionContainer({
  lectureTitle,
  title,
  content,
  profileImg,
  profileName,
  level,
  createdAt,
  viewCount,
}: Props) {
  return (
    <Container>
      <Head>
        <div>
          <QIcon />
          {lectureTitle && <p>{lectureTitle}</p>}
        </div>
      </Head>
      <Content>
        <p className="questionTitle">{title}</p>
        <ViewEditor value={content} />
      </Content>
      <Info>
        {profileImg ? (
          <img src={profileImg} width={24} height={24} alt="user profile" />
        ) : (
          <ProfileIcon />
        )}
        <p>{profileName}</p>
        <UserLevel level={level} />
        <div />
        <p>{moment(createdAt).format("YYYY.MM.DD")}</p>
        <div />
        <p>조회수 {viewCount}</p>
      </Info>
    </Container>
  );
}
