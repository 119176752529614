import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { delVideo } from "service/api/content/getApi";
import useSWR from "swr";
import { HeadText } from "components/header";
import { SearchTable } from "components/table";
import { DetailActionBtn } from "components/button";
import { VideoTable } from "./videoTable";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 33px 0 26px;
`;

export function VideoPage() {
  const navigate = useNavigate();
  const [selectPage, setSelectPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [submitSearch, setSubmitSearch] = useState<string>("");
  const [selectTable, setSelectTable] = useState<string[]>([]);

  const { data, mutate } = useSWR(
    `/admin/video?limit=30&offset=${
      (selectPage - 1) * 30
    }&search=${submitSearch}`,
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      setSubmitSearch("");
    }
  }, [searchValue]);

  const handleSearchBtn = () => {
    setSubmitSearch(searchValue);
  };

  const handleDeleteBtn = async () => {
    const res = await delVideo(selectTable.join(";") + ";");
    mutate();
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.video}/add`);
  };

  return (
    <Cover>
      <HeadText label="영상" margin="0 0 25px" />
      <SearchTable
        rightTitle="검색어"
        searchInputValue={searchValue}
        setSearchInputValue={setSearchValue}
        onClick={handleSearchBtn}
      />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="선택 삭제"
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <VideoTable
        data={data?.data}
        count={data?.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
