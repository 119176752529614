import React from "react";
import ReactDOM from "react-dom/client";
import {RecoilRoot} from 'recoil';
import "./index.css";
import App from "./app/App";
import {BrowserRouter} from "react-router-dom";
import "antd/dist/antd.less";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>,
);
