import React, { useState } from "react";
import styled from "styled-components";
import { postPopularLecture } from "service/api/content/postApi";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { ListTable } from "./listTable";
import { LectureModal } from "pages/contents/roadmap/detail/lectureModal";
import useSWR from "swr";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 25px;
`;

export function PopularPage() {
  const { data, mutate } = useSWR("/admin/popularLecture?limit=50");

  const [isLectureModalVisible, setIsLectureModalVisible] =
    useState<boolean>(false);

  const handleLectureAddBtn = () => {
    setIsLectureModalVisible(true);
  };

  const handleAddBtn = async (lecture: any) => {
    const res = await postPopularLecture([lecture[0].id]);
    mutate();
    setIsLectureModalVisible(false);
  };
  return (
    <Cover>
      <LectureModal
        isVisible={isLectureModalVisible}
        setIsVisible={setIsLectureModalVisible}
        onClick={handleAddBtn}
      />
      <HeadText label="인기강의" />
      <BtnContainer>
        <DetailActionBtn
          label="+강의 추가"
          isFill={false}
          onClick={handleLectureAddBtn}
        />
      </BtnContainer>
      <ListTable data={data?.data} mutate={mutate} />
    </Cover>
  );
}
