import React from "react";
import styled from "styled-components";

interface Props {
  width?: string;
  label: string;
  value: string | number;
  name: string;
  language: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
}

const Cover = styled.div<{ cardWidth?: string }>`
  width: ${({ cardWidth }) => (cardWidth ? cardWidth : "25%")};
  height: 51px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  p {
    margin: 0;
    border-bottom: 1px solid #e3e3e3;
    text-align: center;
    line-height: 20px;
    color: #111827;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 2px 8px;
  font-size: 14px;
`;

export function InputCard({
  width,
  label,
  value,
  name,
  language,
  setValue,
}: Props) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [language]: e.target.value,
      },
    }));
  };

  return (
    <Cover cardWidth={width}>
      <p>{label}</p>
      <Input value={value} onChange={handleInputChange} />
    </Cover>
  );
}
