import AxiosManager from "../_axiosManager";

export async function getProblemDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/problem/${id}`);

  return res;
}

export async function getBannerDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/banner/${id}`);

  return res;
}

export async function delBanner(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/banner?ids=${ids}`,
  );

  return res;
}

export async function getLectureDetail(lectureId: string) {
  const res = await AxiosManager.getInstance().get(
    `/admin/lecture/${lectureId}`,
  );

  return res;
}

export async function getProblemCategory() {
  const res = await AxiosManager.getInstance().get("/problemcategory");

  return res;
}

export async function delProblemCategory(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/problemCategory?ids=${ids}`,
  );

  return res;
}

export async function getRoadmapDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/loadmap/${id}`);

  return res;
}

export async function delRoadmap(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/loadmap?ids=${ids}`,
  );

  return res;
}

export async function getCategoryDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/problemcategory/${id}`);

  return res;
}

export async function delPopularLecture(lectureId: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/popularLecture/${lectureId}`,
  );

  return res;
}

export async function delProblem(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/problem?ids=${ids}`,
  );

  return res;
}

export async function getLectureCategory() {
  const res = await AxiosManager.getInstance().get("/category");

  return res;
}

export async function delLecture(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/lecture?ids=${ids}`,
  );

  return res;
}

export async function getVideo(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/video/${id}`);

  return res;
}

export async function delVideo(ids: string) {
  const res = await AxiosManager.getInstance().delete(
    `/admin/video?ids=${ids}`,
  );

  return res;
}
