import React, { useState } from "react";
import styled from "styled-components";
import { VideoAddModal } from "../Common/videoAddModal";
import { ProblemAddModal } from "../Common/problemAddModal";
import { CurriculumBtn } from "../Common/curriculumBtn";
import { LectureUnit } from "../Common/lectureUnit";
import type { IChangeData } from "./index";

interface Props {
  data: IChangeData;
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
}

const Curriculum = styled.div`
  position: relative;
  min-height: 400px;
  margin: 0 0 26px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 35px;
  p {
    margin: 0;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
  div {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

export function CurriculumContainer({ data, setData }: Props) {
  const [isVideoModal, setIsVideoModal] = useState<boolean>(false);
  const [isProblemModal, setIsProblemModal] = useState<boolean>(false);
  const [selectUnitsIdx, setSelectUnitsIdx] = useState<number | undefined>();

  const handleUnitAddBtn = () => {
    setData((prev) => ({
      ...prev,
      units: prev.units
        ? [
            ...prev.units,
            {
              title: "",
              Videos: [],
              Problems: [],
            },
          ]
        : [
            {
              title: "",
              Videos: [],
              Problems: [],
            },
          ],
    }));
  };
  const handleVideoAddBtn = () => {
    setIsVideoModal(true);
  };
  const handleProblemAddBtn = () => {
    setIsProblemModal(true);
  };

  const handleVideoPick = (selectData: any) => {
    if (typeof selectUnitsIdx !== "undefined") {
      let copiedArr = [...data.units];
      copiedArr[selectUnitsIdx].Videos.push({
        id: selectData.id,
        title: selectData.title,
        sequence: copiedArr[selectUnitsIdx].Problems.length + 1,
      });
      setData((prev) => ({
        ...prev,
        units: copiedArr,
      }));
    } else {
      window.alert("대단원을 선택해주세요");
    }
  };

  const handleProblemPick = (selectData: any) => {
    if (typeof selectUnitsIdx !== "undefined") {
      let copiedArr = [...data.units];
      copiedArr[selectUnitsIdx].Problems.push({
        id: selectData.id,
        title: selectData.title_ko,
      });
      setData((prev) => ({
        ...prev,
        units: copiedArr,
      }));
    } else {
      window.alert("대단원을 선택해주세요");
    }
  };

  return (
    <Curriculum>
      <VideoAddModal
        isVisible={isVideoModal}
        setIsVisible={setIsVideoModal}
        onClick={handleVideoPick}
      />
      <ProblemAddModal
        isVisible={isProblemModal}
        setIsVisible={setIsProblemModal}
        onClick={handleProblemPick}
      />
      <Head>
        <p>커리큘럼</p>
        <div>
          <CurriculumBtn label="+대단원 추가" onClick={handleUnitAddBtn} />
          <CurriculumBtn label="+영상 추가" onClick={handleVideoAddBtn} />
          <CurriculumBtn label="+문제 추가" onClick={handleProblemAddBtn} />
        </div>
      </Head>
      <div>
        <LectureUnit
          data={data}
          setData={setData}
          selectUnitIdx={selectUnitsIdx}
          setSelectUnitIndx={setSelectUnitsIdx}
        />
      </div>
    </Curriculum>
  );
}
