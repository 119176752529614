import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom"
import {Table} from "antd"
import routerConstant from "service/constant/routerConstant";

interface Props {
    data:any
    selectTable: string[];
    setSelectTable: React.Dispatch<React.SetStateAction<string[]>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
img {
    width: 171px;
}
`

export function BannerTabel({data, selectTable, setSelectTable, page, setPage}: Props) {
    const navigate = useNavigate()
    const handleDetailBtn = (id: string) => {
        navigate(`${routerConstant.contents.banner}/${id}`)
    }

    const columns = [
        {
            title: "NO",
            dataIndex: "id"
        },
        {
            title: "이미지",
            dataIndex: "src",
            render: (src: string) => (
                <img src={src} alt="banner" />
            )
        },
        {
            title: "제목",
            dataIndex: "title",
            width: "52%"
        },
        {
            title: "상세보기",
            dataIndex: "id",
            render: (text: string) => (
                <span className="routerBtn" onClick={() => handleDetailBtn(text + "")}>
          바로가기
        </span>
            )
        },
    ]

    const handleSelectTableChange = (selectTable: any) => {
        setSelectTable(selectTable);
    };

    const rowSelection: any = {
        selectedRowKeys: selectTable,
        onChange: handleSelectTableChange,
    };

    return <CustomTable             
    rowKey="id"
    rowSelection={rowSelection}
     columns={columns} dataSource={data.data}
     pagination={{
        current: page,
        pageSize: 30,
        onChange: (page) => setPage(page),
        total: data.count
    }} />
}