import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { getRoadmapDetail } from "service/api/content/getApi";
import { postRoadmap, putRoadmap } from "service/api/content/postApi";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { TableInput } from "components/input";
import { DetailActionBtn } from "components/button";
import { LectureTable } from "./lectureTable";
import { LectureModal } from "./lectureModal";
import routerConstant from "service/constant/routerConstant";

interface IData {
  LoadMapCourses: any[];
  id: number | null;
  title: string;
}

const Cover = styled.div``;

const CategoryTable = styled.table`
  width: 100%;
  margin: 35px 0 45px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 36px;
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin: 30px 0 0;
`;

export function RoadmapDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IData>({
    LoadMapCourses: [],
    title: "",
    id: null,
  });
  const [isLectureAddModalVisibe, setIsLectureAddModalVisible] =
    useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getRoadmapDetail(params.id + "");
        setData(res.data.data);
      })();
    }
  }, [params]);

  const handleLectureAddBtn = () => {
    setIsLectureAddModalVisible(true);
  };

  const handleLectureAdd = (selectLecture: any) => {
    let copiedArr = [...data.LoadMapCourses];
    copiedArr.push({
      id: copiedArr.length + 1,
      LectureId: selectLecture[0]?.id,
      title: selectLecture[0]?.title,
      Lecture: {
        Category: selectLecture[0].Category,
        teacher: selectLecture[0].teacher,
      },
    });

    setData((prev) => ({
      ...prev,
      LoadMapCourses: copiedArr,
    }));
    setIsLectureAddModalVisible(false);
  };

  const handleCategoryInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleCancelBtn = () => {
    navigate(routerConstant.contents.roadmap);
  };

  const handleRoadmapAddBtn = async () => {
    let check: boolean = true;
    data.LoadMapCourses.forEach((list) => {
      if (!list.order) {
        check = false;
      }
    });

    if (check && data.title && data.LoadMapCourses.length !== 0) {
      const res = await postRoadmap({
        title: data.title,
        course: data.LoadMapCourses.map((list) => ({
          title: list.title,
          LectureId: list.LectureId,
          order: list.order,
        })),
      });
      if (res.status === 201) {
        setIsSuccessModalVisible(true);
      } else {
        window.alert("항목을 작성해 주세요");
      }
    } else {
      window.alert("항목을 작성해 주세요");
    }
  };

  const handleRoadmapPutBtn = async () => {
    let check: boolean = true;
    data.LoadMapCourses.forEach((list) => {
      if (!list.order) {
        check = false;
      }
    });

    if (check && data.title && data.LoadMapCourses.length !== 0) {
      const res = await putRoadmap(params.id + "", {
        title: data.title,
        course: data.LoadMapCourses.map((list) => ({
          title: list.title,
          LectureId: list.LectureId,
          order: list.order,
        })),
      });
      if (res.status === 200) {
        setIsSuccessModalVisible(true);
      } else {
        window.alert("항목을 작성해 주세요");
      }
    } else {
      window.alert("항목을 작성해 주세요");
    }
  };
  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 되었습니다."
        onClick={handleCancelBtn}
      />
      <LectureModal
        isVisible={isLectureAddModalVisibe}
        setIsVisible={setIsLectureAddModalVisible}
        onClick={handleLectureAdd}
      />
      <HeadText label="로드맵 상세" />
      <CategoryTable>
        <tr>
          <th>카테고리명*</th>
          <td>
            <TableInput
              value={data.title}
              onChange={handleCategoryInput}
              placeholder="카테고리명 입력"
            />
          </td>
        </tr>
      </CategoryTable>
      <AddContainer>
        <p>강의</p>
        <DetailActionBtn
          label="+강의 추가"
          isFill={false}
          onClick={handleLectureAddBtn}
        />
      </AddContainer>
      <LectureTable data={data.LoadMapCourses} setData={setData} />
      <BtnContainer>
        <DetailActionBtn
          label="목록으로"
          isFill={false}
          onClick={handleCancelBtn}
        />
        <DetailActionBtn
          label="저장하기"
          onClick={
            params.id === "add" ? handleRoadmapAddBtn : handleRoadmapPutBtn
          }
        />
      </BtnContainer>
    </Cover>
  );
}
