import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { IChangeData } from "./detail";
import moment from "moment";

interface Props {
  changeData: IChangeData;
  setChangeData: React.Dispatch<React.SetStateAction<IChangeData>>;
  adminOption: any[];
  setAdminOption: React.Dispatch<React.SetStateAction<any[]>>;
}

const Cover = styled.div`
  margin: 38px 0 15px;
  .tableTitle {
    margin: 0 0 20px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 51px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .input {
    height: 75px;
  }
`;

const Input = styled.input`
  width: 328px;
  height: 51px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
`;

const CustomCheckBox = styled(Checkbox)`
  margin: 0;
  color: #111827;
  font-size: 16px;
`;

export function DetailTable({
  changeData,
  setChangeData,
  adminOption,
  setAdminOption,
}: Props) {
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangeData((prev) => ({
      ...prev,
      password: e.target.value,
    }));
  };

  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangeData((prev) => ({
      ...prev,
      nickName: e.target.value,
    }));
  };
  const handleCheckBoxClick = (checkedValues: CheckboxValueType[]) => {
    setAdminOption(checkedValues);
  };

  const options = [
    { label: "커뮤니티", value: "community" },
    { label: "강의", value: "lecture" },
    { label: "문제", value: "problem" },
  ];

  return (
    <Cover>
      <p className="tableTitle">기본정보</p>
      <Table>
        <tbody>
          <tr>
            <th>계정구분*</th>
            <td>{changeData?.isSuperAdmin ? "최고관리자" : "관리자"}</td>
            <th>아이디*</th>
            <td>{changeData?.adminId}</td>
          </tr>
          <tr>
            <th className="input">비밀번호*</th>
            <td className="input">
              <Input
                type="password"
                value={changeData?.password}
                onChange={handlePasswordInput}
              />
            </td>
            <th className="input">이름*</th>
            <td className="input">
              <Input value={changeData?.nickName} onChange={handleNameInput} />
            </td>
          </tr>
          <tr>
            <th>계정 권한</th>
            <td>
              <CustomCheckBox.Group
                options={options}
                value={adminOption}
                onChange={handleCheckBoxClick}
              />{" "}
            </td>
            <th>계정 생성일</th>
            <td>
              {moment(changeData.createdAt).format("YYYY.MM.DD HH:mm:ss")}
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
