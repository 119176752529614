import React from "react";
import styled from "styled-components";

interface Props {
  isFill?: boolean;
  label: string;
  onClick: () => void;
}

const Btn = styled.button<{ isFill: boolean }>`
  height: 49px;
  padding: 8px 24px;
  border: 1px solid #6c757d;
  background-color: ${({ isFill }) => (isFill ? "#6c757d" : "#fff")};
  color: ${({ isFill }) => (isFill ? "#fff" : "#6c757d")};
  font-size: 19px;
  cursor: pointer;
`;

export function DetailActionBtn({ isFill = true, label, onClick }: Props) {
  return (
    <Btn isFill={isFill} onClick={onClick}>
      {label}
    </Btn>
  );
}
