import React, { useState } from "react";
import styled from "styled-components";
import { Layout, Menu, Popover } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import siderMenuConstant from "service/constant/sideMenuConstant";
import profile from "asset/img/profile.png";
import styleConstant from "service/constant/styleConstant";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const CustomSider = styled(Sider)`
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 999;
  overflow: auto;
  height: 100vh;
  padding-top: 16px;
  background-color: white;
  border-right: 1px solid #f0f0f0;
`;

const CustomMenu = styled(Menu)`
  padding-bottom: 150px;
  .ant-menu-sub.ant-menu-inline {
    background-color: #fff !important;
  }
  .ant-menu-submenu-selected {
    background-color: #f3f4f6 !important;
    color: #1d1d1d;
  }
  .ant-menu-item-selected {
    background-color: #fff !important;
  }
  .ant-menu-item-selected a {
    color: #1d1d1d !important;
  }
  .ant-menu-item::after {
    border-right: 3px solid #919191 !important;
  }
`;

const HeaderContainer = styled.div`
  position: fixed;
  box-sizing: border-box;
  /* min-width: 1440px; */
  width: 100%;
  padding: 0 24px;
  z-index: 1000;
  box-shadow: 0 2px 8px #f0f1f2;
`;

const CustomHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* min-width: 1440px; */
  margin: 0 auto;
  padding: 0px;
  background-color: white;
`;

const PopoverContents = styled.div`
  width: 150px;
`;

const ProfileImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  cursor: pointer;
`;

const Body = styled.div`
  width: 1190px;
  min-width: 1190px;
  /* margin: 0 atuo; */
  padding: 48px 40px;
`;

const CustomContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 0;
  transition: all 0.2s;
  min-width: 1190px;
`;

interface Props {
  children: React.ReactNode;
}

export function SideBar({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const splitLocation = location.pathname.split("/");
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isVisibleProfilePopover, setIsVisibleProfilePopover] =
    useState<boolean>(false);

  const handleSiderOnCollapse = (collapsedState: boolean) => {
    setIsCollapsed(collapsedState);
  };

  const handleLogoutBtn = () => {
    localStorage.clear();
    navigate("/");
  };

  const items = siderMenuConstant;

  const handleMenuClick = (key: any) => {
    navigate(key.key);
  };

  return (
    <Layout>
      <CustomSider
        collapsed={isCollapsed}
        onCollapse={(collapsedState: boolean) =>
          handleSiderOnCollapse(collapsedState)
        }
        // side bar width
        width={250}
      >
        <CustomMenu
          mode="inline"
          activeKey={location.pathname}
          defaultOpenKeys={[location.pathname]}
          onClick={handleMenuClick}
          items={items}
          // detail page (ex. /blog/:id)
          selectedKeys={[`/${splitLocation[1]}/${splitLocation[2]}`]}
        />
      </CustomSider>
      <Layout
        style={{
          transition: "all 0.2s",
          minHeight: "100vh",
          backgroundColor: "white",
        }}
      >
        <HeaderContainer>
          <CustomHeader>
            <MenuOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed((prevState) => !prevState)}
            />
            <Popover
              trigger="click"
              placement="bottomRight"
              content={
                <PopoverContents>
                  <span
                    onClick={handleLogoutBtn}
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    로그아웃
                  </span>
                </PopoverContents>
              }
              visible={isVisibleProfilePopover}
              onVisibleChange={(visibleState) =>
                setIsVisibleProfilePopover(visibleState)
              }
            >
              {/* 프로필 이미지 */}
              <ProfileImage src={profile} />
            </Popover>
          </CustomHeader>
        </HeaderContainer>
        <div style={{ height: "64px" }} />
        <CustomContent style={{ marginLeft: isCollapsed ? "80px" : "260px" }}>
          <Body>{children}</Body>
        </CustomContent>
      </Layout>
    </Layout>
  );
}
