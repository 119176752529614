import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import tokenConstant from "service/constant/tokenConstant";

export function PrivateRoute({ children }: any) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(tokenConstant.ACCESS_TOKEN),
  );

  useEffect(() => {
    setAccessToken(localStorage.getItem(tokenConstant.ACCESS_TOKEN));
  }, []);

  return accessToken ? children : <Navigate to="/" />;
}
