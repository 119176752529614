import React from "react";
import styled from "styled-components";
import { Table } from "antd";
import moment from "moment";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .cancel {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function ListTable({ data, count, selectPage, setSelectPage }: Props) {
  const columns = [
    {
      title: "No",
      dataIndex: "id",
    },
    {
      title: "결제일",
      dataIndex: "createdAt",
      render: (text: string) => <>{moment(text).format("YYYY.MM.DD")}</>,
    },
    {
      title: "닉네임",
      dataIndex: "User",
      render: (user: any) => <>{user?.nickName}</>,
    },
    {
      title: "이메일",
      dataIndex: "User",
      render: (user: any) => <>{user?.email}</>,
    },
    {
      title: "핸드폰",
      dataIndex: "User",
      render: (user: any) => <>{user?.phoneNumber}</>,
    },
    {
      title: "상품명",
      dataIndex: "MemberShip",
      render: (membership: any) => <>{membership?.name}</>,
    },
    {
      title: "금액",
      dataIndex: "MemberShip",
      render: (membership: any) => <>{membership?.discountAmount}</>,
    },
    {
      title: "구독 취소",
      dataIndex: "",
      // render: () => <span className="routerBtn">바로가기</span>,
    },
  ];

  return (
    <CustomTable
      columns={columns}
      dataSource={data}
      pagination={{
        current: selectPage,
        pageSize: 30,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
