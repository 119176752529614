import React, { useState } from "react";
import styled from "styled-components";
import {
  DeleteOutlined,
  PlayCircleOutlined,
  EditOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { VideoAddModal } from "../Common/videoAddModal";
import { ProblemAddModal } from "../Common/problemAddModal";
import type { IChangeData } from "../Detail";
import styleConstant from "service/constant/styleConstant";

interface Props {
  data: IChangeData;
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
  selectUnitIdx: number | undefined;
  setSelectUnitIndx: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const Cover = styled.div``;

const NoneData = styled.div`
  margin: 180px auto 123px;
  text-align: center;
  p:first-child {
    margin: 0 0 6px;
    line-height: 30px;
    color: ${styleConstant.COLOR.gray_4};
    font-size: 24px;
    font-weight: 700;
  }
  p:nth-child(2) {
    margin: 0;
    line-height: 20px;
    color: ${styleConstant.COLOR.gray_4};
    font-size: 16px;
    font-weight: 400;
  }
`;

const Unit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
  margin: 0 0 26px;
`;

const UnitTitle = styled.div<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  padding: 13px 12px;
  border: ${({ isSelect }) =>
    isSelect ? `2px solid ${styleConstant.COLOR.main_O}` : ""};
  background-color: #f5f6fa;
  cursor: pointer;
  input {
    width: 944px;
    height: 100%;
    margin: 0;
    padding: 8px 24px;
    border: 1px solid #eee;
    border-radius: 4px;
    outline: none;
    line-height: 175%;
    color: #111827;
    font-size: 19px;
    font-weight: 400;
  }
`;

const UnitList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  padding: 20px 24px;
  background-color: #f5f6fa;
  div:first-child {
    width: 930px;
    p {
      margin: 0;
      margin-left: 13px;
      color: #111827;
      font-size: 19px;
      font-weight: 400;
    }
  }
  div {
    display: flex;
    align-items: center;
    gap: 30px;
  }
`;

export function LectureUnit({
  data,
  setData,
  selectUnitIdx,
  setSelectUnitIndx,
}: Props) {
  const [isVideoModal, setIsVideoModal] = useState<boolean>(false);
  const [isProblemModal, setIsProblemModal] = useState<boolean>(false);
  const [selectEditIdx, setSelectEditIdx] = useState<{
    unit: number;
    content: number;
  }>({ unit: 0, content: 0 });

  const handleUnitClick = (idx: number) => {
    setSelectUnitIndx(idx);
  };

  const handleUnitTitleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    let copiedArr = [...data.units];
    copiedArr[idx].title = e.target.value;
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  const handleUnitRemove = (idx: number) => {
    let copiedArr = [...data.units];
    copiedArr.splice(idx, 1);
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  const handleVideoRemove = (unitIdx: number, videoIdx: number) => {
    let copiedArr = [...data.units];
    copiedArr[unitIdx].Videos.splice(videoIdx, 1);
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  const handleProblemRemove = (unitIdx: number, problemIdx: number) => {
    let copiedArr = [...data.units];
    copiedArr[unitIdx].Problems.splice(problemIdx, 1);
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  const handleVideoEditBtn = (unitIdx: number, videoIdx: number) => {
    setIsVideoModal(true);
    setSelectEditIdx({
      unit: unitIdx,
      content: videoIdx,
    });
  };

  const handleProblemEditBtn = (unitIdx: number, problemIdx: number) => {
    setIsProblemModal(true);
    setSelectEditIdx({
      unit: unitIdx,
      content: problemIdx,
    });
  };

  const handleVideoPick = (selectData: any) => {
    let copiedArr = [...data.units];
    copiedArr[selectEditIdx.unit].Videos[selectEditIdx.content] = {
      id: selectData.id,
      title: selectData.title,
      sequence:
        copiedArr[selectEditIdx.unit].Videos[selectEditIdx.content].sequence,
    };
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  const handleProblemPick = (selectData: any) => {
    let copiedArr = [...data.units];
    copiedArr[selectEditIdx.unit].Problems[selectEditIdx.content] = {
      id: selectData.id,
      title: selectData.title_ko,
    };
    setData((prev) => ({
      ...prev,
      units: copiedArr,
    }));
  };

  return (
    <Cover>
      <VideoAddModal
        isVisible={isVideoModal}
        setIsVisible={setIsVideoModal}
        onClick={handleVideoPick}
      />
      <ProblemAddModal
        isVisible={isProblemModal}
        setIsVisible={setIsProblemModal}
        onClick={handleProblemPick}
      />
      {data.units?.length === 0 ? (
        <NoneData>
          <p>커리큘럼 없음</p>
          <p>대단원/영상/문제 추가해 커리큘럼을 구성해주세요</p>
        </NoneData>
      ) : (
        data.units?.map((unit, unitIdx) => (
          <Unit key={unitIdx}>
            <UnitTitle
              isSelect={unitIdx === selectUnitIdx}
              onClick={() => handleUnitClick(unitIdx)}
            >
              <input
                value={unit.title}
                onChange={(e) => handleUnitTitleInput(e, unitIdx)}
              />
              <DeleteOutlined
                onClick={() => handleUnitRemove(unitIdx)}
                style={{
                  fontSize: "18px",
                  marginRight: "13px",
                  cursor: "pointer",
                }}
              />
            </UnitTitle>
            {data.units[unitIdx]?.Videos?.map((video, videoIdx) => (
              <UnitList key={videoIdx}>
                <div>
                  <PlayCircleOutlined style={{ fontSize: "24px" }} />
                  <p>{video.title}</p>
                </div>
                <div>
                  <EditOutlined
                    onClick={() => handleVideoEditBtn(unitIdx, videoIdx)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                  <DeleteOutlined
                    onClick={() => handleVideoRemove(unitIdx, videoIdx)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                </div>
              </UnitList>
            ))}
            {data.units[unitIdx]?.Problems?.map((problem, problemIdx) => (
              <UnitList key={problemIdx}>
                <div>
                  <CodeOutlined style={{ fontSize: "24px" }} />
                  <p>{problem.title}</p>
                </div>
                <div>
                  <EditOutlined
                    onClick={() => handleProblemEditBtn(unitIdx, problemIdx)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                  <DeleteOutlined
                    onClick={() => handleProblemRemove(unitIdx, problemIdx)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                </div>
              </UnitList>
            ))}
          </Unit>
        ))
      )}
    </Cover>
  );
}
