import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { delProblemCategory } from "service/api/content/getApi";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { ListTable } from "./listTable";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 33px 0 26px;
`;

export function CategoryPage() {
  const navigate = useNavigate();
  const [listData, setListData] = useState<{ count: number; data: any[] }>({
    count: 0,
    data: [],
  });
  const [selectpage, setSelectPage] = useState<number>(1);
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const { data, mutate } = useSWR(
    `/problemcategory?limit=30&offset=${(selectpage - 1) * 30}`,
  );

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  const handleDeleteBtn = async () => {
    const res = await delProblemCategory(selectTable.join(";") + ";");
    mutate();
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.contents.category}/add`);
  };

  return (
    <Cover>
      <HeadText label="문제 카테고리" />
      <BtnContainer>
        <DetailActionBtn
          label="선택 삭제"
          isFill={false}
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <ListTable
        data={listData.data}
        count={listData.count}
        selectPage={selectpage}
        setSelectPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
