import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getProblemDetail } from "service/api/content/getApi";
import { postProblem, putProblem } from "service/api/content/postApi";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { ProblemDetailTable } from "./problemDetailTale";
import Editor from "components/editor";
import { TableInput } from "components/input";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

export interface IChangeData {
  title: string;
  category: number;
  limitSeconds: {
    C: number;
    Cpp: number;
    Java: number;
    Python: number;
  };
  limitMemory: {
    C: string;
    Cpp: string;
    Java: string;
    Python: string;
  };
  limitLoop: string;
  language: string[];
}

const Cover = styled.div``;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0 104px;
  .title {
    margin: 0 0 15px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
  .margin {
    margin-top: 40px;
  }
  .container {
    min-height: 76px;
    padding: 12px 16px;
    border: 1px solid #eee;
  }
  .example {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 52px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  outline: none;
  resize: none;
  line-height: 20px;
  color: #4a4a4a;
  font-size: 16px;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export function ProbolemDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [changeData, setChangeData] = useState<IChangeData>({} as IChangeData);
  const [problemEditValue, setProblemEditValue] = useState<string>("");
  const [inputEditValue, setInputEditValue] = useState<string>("");
  const [outputEditValue, setOutputEditValue] = useState<string>("");
  const [inputExampleValue, setInputExampleValue] = useState<string[]>([""]);
  const [outputExampleValue, setOutputExampleValue] = useState<string[]>([""]);
  // const [commentValue, setCommentValue] = useState<string>("");
  const [sourceValue, setSourceValue] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const [isErrModalVisible, setIsErrModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const detailRes = await getProblemDetail(params.id + "");
        setChangeData({
          title: detailRes.data.data.title_ko,
          category: detailRes.data.data.ProblemCategoryId,
          limitSeconds: detailRes.data.data.time_limit[0]
            ? {
                C: detailRes.data.data.time_limit[0],
                Cpp: detailRes.data.data.time_limit[0],
                Java: detailRes.data.data.time_limit[0],
                Python: detailRes.data.data.time_limit[0],
              }
            : {
                C: detailRes.data.data.time_limit[1],
                Cpp: detailRes.data.data.time_limit[2],
                Java: detailRes.data.data.time_limit[3],
                Python: detailRes.data.data.time_limit[4],
              },
          limitMemory: detailRes.data.data.memory_limit[0]
            ? {
                C: detailRes.data.data.memory_limit[0],
                Cpp: detailRes.data.data.memory_limit[0],
                Java: detailRes.data.data.memory_limit[0],
                Python: detailRes.data.data.memory_limit[0],
              }
            : {
                C: detailRes.data.data.memory_limit[1],
                Cpp: detailRes.data.data.memory_limit[2],
                Java: detailRes.data.data.memory_limit[3],
                Python: detailRes.data.data.memory_limit[4],
              },
          limitLoop:
            detailRes.data.data.loop_limit === -1
              ? ""
              : detailRes.data.data.loop_limit,
          language: detailRes.data.data.language_limit[0]
            ? ["C", "C++", "Java", "Python"]
            : [],
        });
        setProblemEditValue(detailRes.data.data.description_ko);
        setInputEditValue(detailRes.data.data.input_format_ko);
        setOutputEditValue(detailRes.data.data.output_format_ko);
        setSourceValue(detailRes.data.data.authorShip);
        setInputExampleValue(
          detailRes.data.data.Testcases.map((test: any) => test.input),
        );
        setOutputExampleValue(
          detailRes.data.data.Testcases.map((test: any) => test.output),
        );
        setIsUpdated(true);
      })();
    }
  }, [params]);

  const handleInputExampleAddBtn = () => {
    setInputExampleValue((prev) => [...prev, ""]);
    setOutputExampleValue((prev) => [...prev, ""]);
  };

  const handleInputExampleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    let copiedArr = [...inputExampleValue];
    copiedArr[idx] = e.target.value;
    setInputExampleValue(copiedArr);
  };

  const handleOutputExampleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    let copiedArr = [...outputExampleValue];
    copiedArr[idx] = e.target.value;
    setOutputExampleValue(copiedArr);
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSourceValue(e.target.value);
  };

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const handleSaveBtn = async () => {
    if (params.id === "add") {
      const res = await postProblem({
        title_ko: changeData.title,
        is_spj: true,
        description_ko: problemEditValue,
        input_format_ko: inputEditValue,
        output_format_ko: outputEditValue,
        language_limit: {
          0: changeData.language.length === 0 ? 0 : 1,
          1: changeData.language.includes("C") ? 1 : 0,
          2: changeData.language.includes("C++") ? 1 : 0,
          3: changeData.language.includes("Java") ? 1 : 0,
          4: changeData.language.includes("Python") ? 1 : 0,
        },
        memory_limit: {
          0: changeData.limitMemory.C,
          1: changeData.limitMemory.C,
          2: changeData.limitMemory.Cpp,
          3: changeData.limitMemory.Java,
          4: changeData.limitMemory.Python,
        },
        time_limit: {
          0: Number(changeData.limitSeconds.Python),
          1: Number(changeData.limitSeconds.C),
          2: Number(changeData.limitSeconds.Cpp),
          3: Number(changeData.limitSeconds.Java),
          4: Number(changeData.limitSeconds.Python),
        },
        loop_limit: changeData.limitLoop ? changeData.limitLoop : -1,
        authorShip: sourceValue,
        ProblemCategoryId: changeData.category,
        Testcases: inputExampleValue.map((list, idx) => ({
          input: list,
          output: outputExampleValue[idx],
          order: idx,
        })),
      });
      if (res.status === 201) {
        setIsSuccessModalVisible(true);
      } else if (res.status === 220) {
        setIsErrModalVisible(true);
      }
    } else {
      const res = await postProblem({
        title_ko: changeData.title,
        is_spj: true,
        description_ko: problemEditValue,
        input_format_ko: inputEditValue,
        output_format_ko: outputEditValue,
        language_limit: {
          0: changeData.language.length === 0 ? 0 : 1,
          1: changeData.language.includes("C") ? 1 : 0,
          2: changeData.language.includes("C++") ? 1 : 0,
          3: changeData.language.includes("Java") ? 1 : 0,
          4: changeData.language.includes("Python") ? 1 : 0,
        },
        memory_limit: {
          0: changeData.limitMemory.C,
          1: changeData.limitMemory.C,
          2: changeData.limitMemory.Cpp,
          3: changeData.limitMemory.Java,
          4: changeData.limitMemory.Python,
        },
        time_limit: {
          0: Number(changeData.limitSeconds.Python),
          1: Number(changeData.limitSeconds.C),
          2: Number(changeData.limitSeconds.Cpp),
          3: Number(changeData.limitSeconds.Java),
          4: Number(changeData.limitSeconds.Python),
        },
        loop_limit: changeData.limitLoop ? changeData.limitLoop : -1,
        authorShip: sourceValue,
        ProblemCategoryId: changeData.category,
        Testcases: inputExampleValue.map((list, idx) => ({
          input: list,
          output: outputExampleValue[idx],
          order: idx,
        })),
      });
      if (res.status === 201) {
        setIsSuccessModalVisible(true);
      } else if (res.status === 220) {
        setIsErrModalVisible(true);
      }
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.contents.problem);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <OneBtnModal
        isVisible={isErrModalVisible}
        setIsVisible={setIsErrModalVisible}
        text="권한이 없습니다"
      />
      <HeadText label="문제 상세" margin="0 0 28px" />
      <ProblemDetailTable data={changeData} setData={setChangeData} />
      <EditContainer>
        <div>
          <p className="title">문제*</p>
          {!isUpdated && (
            <Editor height="379px" setValue={setProblemEditValue} />
          )}
          {isUpdated && (
            <Editor
              height="379px"
              value={problemEditValue}
              setValue={setProblemEditValue}
            />
          )}
        </div>
        <div>
          <p className="title margin">Input*</p>
          {!isUpdated && <Editor height="379px" setValue={setInputEditValue} />}
          {isUpdated && (
            <Editor
              height="379px"
              value={inputEditValue}
              setValue={setInputEditValue}
            />
          )}
        </div>
        <div>
          <p className="title margin">Output*</p>
          {!isUpdated && (
            <Editor height="379px" setValue={setOutputEditValue} />
          )}
          {isUpdated && (
            <Editor
              height="379px"
              value={outputEditValue}
              setValue={setOutputEditValue}
            />
          )}
        </div>
        <div>
          <p className="title margin example">
            입력 예시*{" "}
            <DetailActionBtn
              label="+추가하기"
              isFill={false}
              onClick={handleInputExampleAddBtn}
            />
          </p>
          <div className="container">
            {inputExampleValue.map((input, idx) => (
              <Textarea
                key={idx}
                value={input}
                onChange={(e) => handleInputExampleChange(e, idx)}
              />
            ))}
          </div>
        </div>
        <div>
          <p className="title example">출력 예시* </p>
          <div className="container">
            {outputExampleValue.map((output, idx) => (
              <Textarea
                key={idx}
                value={output}
                onChange={(e) => handleOutputExampleChange(e, idx)}
              />
            ))}
          </div>
        </div>
        {/* <div>
          <p className="title">해설*</p>
          <Editor
            height="379px"
            value={commentValue}
            setValue={setCommentValue}
          />
        </div> */}
        <div>
          <p className="title">출처*</p>
          <TableInput value={sourceValue} onChange={handleSourceChange} />
        </div>
      </EditContainer>
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="목록으로"
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
