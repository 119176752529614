import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { IProbleList } from "service/interface/problem";
import routerConstant from "service/constant/routerConstant";

interface Props {
  data: IProbleList[];
  total: number;
  selectTable: string[];
  setSelectTable: React.Dispatch<React.SetStateAction<string[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function ProblemTable({
  data,
  total,
  selectTable,
  setSelectTable,
  page,
  setPage,
}: Props) {
  const navigate = useNavigate();

  const handleDetailBtn = (id: string) => {
    navigate(`${routerConstant.contents.problem}/${id}`);
  };

  const columns = [
    {
      title: "NO",
      dataIndex: "id",
    },
    {
      title: "문제ID",
      dataIndex: "id",
    },
    {
      title: "문제명",
      dataIndex: "title_ko",
    },
    {
      title: "언어",
      dataIndex: "language_limit",
      render: (language: any) => {
        switch (language["0"]) {
          case 1:
            return <>C, C++, Java, Python</>;
          case 0:
            let text: string[] = [];
            if (language["1"]) text.push("C");
            if (language["2"]) text.push("C++");
            if (language["3"]) text.push("Java");
            if (language["4"]) text.push("Python");
            return <>{text.join(", ")}</>;
        }
      },
    },
    {
      title: "상세보기",
      dataIndex: "id",
      render: (id: number) => (
        <span className="routerBtn" onClick={() => handleDetailBtn(id + "")}>
          바로가기
        </span>
      ),
    },
  ];

  const handleSelectTableChange = (selectTable: any) => {
    setSelectTable(selectTable);
  };

  const rowSelection: any = {
    selectedRowKeys: selectTable,
    onChange: handleSelectTableChange,
  };

  return (
    <CustomTable
      rowKey="id"
      rowSelection={rowSelection}
      dataSource={data}
      columns={columns}
      pagination={{
        pageSize: 30,
        total: total,
        current: page,
        onChange: (idx) => setPage(idx),
        showSizeChanger: false,
      }}
    />
  );
}
