import React from "react";
import styleConstant from "service/constant/styleConstant";
import styled from "styled-components";
import { ReactComponent as AIcon } from "asset/icon/community/aIcon.svg";
import { QnAAnswerCard } from "components/community";

interface Props {
  Comments: any[];
}

const Container = styled.div``;

const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 32px 0 18px;
  p {
    margin: 0;
    line-height: 20px;
    color: ${styleConstant.COLOR.gray_2};
    font-size: 16px;
    font-weight: 700;
  }
  svg {
    margin-bottom: 4px;
  }
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export function AnswerContainer({ Comments }: Props) {
  return (
    <Container>
      <Head>
        <AIcon />
        <p>답변</p>
      </Head>
      <Answer>
        {Comments?.map((answer: any, idx: number) => (
          <QnAAnswerCard
            key={idx}
            name={answer.User?.nickName}
            level={answer.User?.level!}
            createdAt={answer.createdAt}
            profileImg={answer.User?.profileImg}
            content={answer.content}
          />
        ))}
      </Answer>
    </Container>
  );
}
