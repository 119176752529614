import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { AddModalSearchTable } from "components/table";
import { AddProblemTable } from "pages/contents/Lecture_/Common/addProblemTable";
import { ReactComponent as CancelIcon } from "asset/icon/lecture/cancelIcon.svg";
import styleConstant from "service/constant/styleConstant";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: (data: any) => void;
}

interface IProblem {
  id: number;
  title_ko: string;
}

const Cover = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0px;
  right: 0;
  display: ${({ isVisible }) => (isVisible ? "initial" : "none")};
  width: 743px;
  height: 661px;
  padding: 24px 40px 33px 34px;
  border: 1px solid ${styleConstant.COLOR.line_1};
  background-color: #fff;
  svg {
    cursor: pointer;
  }
  .title {
    margin: 6px 0 22px;
    text-align: center;
    line-height: 28px;
    color: ${styleConstant.COLOR.gray_1};
    font-size: 20px;
    font-weight: 500;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const Btn = styled.button<{ isFilled: boolean }>`
  width: 62px;
  height: 48px;
  margin: 0;
  border: 1px solid #b2b2b2;
  background-color: ${({ isFilled }) => (isFilled ? "#b2b2b2" : "#fff")};
  color: ${({ isFilled }) => (isFilled ? "#fff" : "#b2b2b2")};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`;

export function ProblemAddModal({ isVisible, setIsVisible, onClick }: Props) {
  const [category, setCategory] = useState<string>("전체");
  const [selectPage, setSelectPage] = useState<number>(1);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [problemData, setProblemData] = useState<{
    count: number;
    data: IProblem[];
  }>({ count: 0, data: [] });
  const [selectProblemIdx, setSelectProblemIdx] = useState<number[]>([]);

  const { data } = useSWR(
    `/admin/problem?limit=5&offset=${
      (selectPage - 1) * 5
    }&search=${searchInputValue}`,
  );

  useEffect(() => {
    setCategory("전체");
    setSearchInputValue("");
    setSelectProblemIdx([]);
    setSelectPage(1);
  }, [isVisible]);

  useEffect(() => {
    data && setProblemData(data);
  }, [data]);

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const handleSubmitBtn = () => {
    if (selectProblemIdx.length !== 0) {
      onClick(
        problemData.data.filter((list) => list.id === selectProblemIdx[0])[0],
      );
      setIsVisible(false);
    } else {
      window.alert("문제를 선택해주세요");
    }
  };

  return (
    <Cover isVisible={isVisible}>
      <CancelIcon onClick={handleCancelClick} />
      <p className="title">문제 추가</p>
      <AddModalSearchTable
        rightTitle="검색어"
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />
      <AddProblemTable
        data={problemData.data}
        count={problemData.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectProblem={selectProblemIdx}
        setSelectProblem={setSelectProblemIdx}
      />
      <BtnContainer>
        <Btn isFilled={false} onClick={handleCancelClick}>
          취소
        </Btn>
        <Btn isFilled={true} onClick={handleSubmitBtn}>
          확인
        </Btn>
      </BtnContainer>
    </Cover>
  );
}
