import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import routerConstant from "service/constant/routerConstant";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
  selectTable: string[];
  setSelectTable: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function ListTable({
  data,
  count,
  selectPage,
  setSelectPage,
  selectTable,
  setSelectTable,
}: Props) {
  const navigaet = useNavigate();
  const handleDetailBtn = (id: string) => {
    navigaet(`${routerConstant.contents.category}/${id}`);
  };

  const columns = [
    {
      title: "NO",
      dataIndex: "id",
      width: "10%",
    },
    {
      title: "카테고리",
      dataIndex: "name",
      width: "60%",
    },
    {
      title: "문제 수",
      dataIndex: "count",
      width: "15%",
    },
    {
      title: "상세보기",
      dataIndex: "id",
      width: "10%",
      render: (id: number) => (
        <span className="routerBtn" onClick={() => handleDetailBtn(id + "")}>
          바로가기
        </span>
      ),
    },
  ];

  const handleSelectTableChange = (selectTable: any) => {
    setSelectTable(selectTable);
  };

  const rowSelection: any = {
    selectedRowKeys: selectTable,
    onChange: handleSelectTableChange,
  };

  return (
    <CustomTable
      rowKey="id"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={{
        current: selectPage,
        pageSize: 30,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
