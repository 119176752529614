import React from "react";
import styled from "styled-components";

interface Props {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  [key: string]: any;
}

const Input = styled.input`
  width: 100%;
  height: 51px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  &::placeholder {
    color: #8a92a6;
  }
`;

export function TableInput({ value, onChange, ...rest }: Props) {
  return <Input value={value} onChange={onChange} {...rest} />;
}
