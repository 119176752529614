import AxiosManager from "../_axiosManager";

export async function getTermDetail(id: string) {
  const res = await AxiosManager.getInstance().get(`/admin/term/${id}`);

  return res;
}

export async function postTerm(body: any) {
  const res = await AxiosManager.getInstance().post("/admin/term", body);

  return res;
}

export async function putTerm(id: string, body: any) {
  const res = await AxiosManager.getInstance().put(`/admin/term/${id}`, body);

  return res;
}

export async function delTerm(ids: string) {
  const res = await AxiosManager.getInstance().delete(`/admin/term?ids=${ids}`);

  return res;
}
