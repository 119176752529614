import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import QuillEditor, { Quill } from "quill";
import { postImgUpload } from "service/api/common/index";
import { modules, formats } from "./editorProps";
import { ReactComponent as Line } from "assets/icons/line.svg";
import "react-quill/dist/quill.snow.css";
export { ViewEditor } from "./viewEditor";

interface Props {
  width?: string;
  height?: string;
  margin?: string;
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Editor = styled(ReactQuill)<{
  width: string;
  height: string | undefined;
  margin: string | undefined;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  .ql-editor {
    font-family: "Inter, sans-serif" !important;
  }
  img {
    margin: 30px 0 !important;
  }
  .ql-container.ql-snow {
    position: static !important;
    padding: 16px;
    border: 1px solid #e3e3e3;
    .ql-editor {
      padding: 0;
    }
    .ql-editor.ql-blank::before {
      color: #c1c1c1;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-family: "Inter, sans-serif";
    }
  }
  .ql-toolbar.ql-snow {
    /* height: ; */
    border: 1px solid #e3e3e3;
    /* overflow-y: scroll; */
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #a9a9a9;
    }
  }
  .ql-size-small {
    font-size: 14px !important;
  }
  p {
    font-size: 16px;
  }
  .ql-size-large {
    font-size: 22px;
  }
  .ql-size-huge {
    font-size: 24px;
  }
  li {
    margin-bottom: 8px;
    font-size: 16px;
  }
  img {
    margin: 30px 0 !important;
  }
  .ql-hr::after {
    font-size: 18px;
    line-height: 20px;
    content: "hr";
  }
`;

export default function EditorIndex({
  width = "100%",
  height,
  margin,
  value,
  setValue,
}: Props) {
  const editorRef = useRef<ReactQuill & { editor: QuillEditor }>(null);

  const handleImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const formData = new FormData();
      input.files && formData.append("image", input.files[0]);
      if (editorRef.current) {
        const { getEditor } = editorRef.current;
        const range = getEditor().getSelection(true);
        try {
          getEditor().deleteText(range.index, 1);
          const urlRes: any = await postImgUpload(formData);
          getEditor().insertEmbed(range.index, "image", urlRes.data.data.src);
          getEditor().setSelection(range.index + 1, 1);
        } catch (e) {
          console.log("err");
          getEditor().deleteText(range.index, 1);
        }
      }
    };
  };

  useEffect(() => {
    if (editorRef.current) {
      const toolbar = editorRef.current.getEditor().getModule("toolbar");
      toolbar.addHandler("image", handleImage);
      editorRef.current?.editor.root.setAttribute("spellcheck", "false");
    }
  }, []);

  useEffect(() => {
    if (value) {
    }
  }, [value]);

  const handleEditorChange = (editor: any) => {
    setValue(editor);
  };

  return (
    <Editor
      width={width}
      height={height}
      margin={margin}
      ref={editorRef}
      modules={modules}
      formats={formats}
      defaultValue={value}
      onChange={handleEditorChange}
      theme="snow"
    />
  );
}
