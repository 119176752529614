import axios, { AxiosInstance } from "axios";
import tokenManager from "./tokenManager";
import tokenConstant from "service/constant/tokenConstant";

interface ITokenCheck {
  accessToken: string | null;
}

export default class AxiosManager {
  private static instance: AxiosManager | null = null;

  private static axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  public static getInstance() {
    if (this.instance === null) {
      this.instance = new AxiosManager();
    }
    return this.axiosCustom(tokenManager.getAccessToken());
  }

  private static axiosCustom(tokenCheck: ITokenCheck) {
    this.axiosInstance.interceptors.request.use((config) => {
      if (config.url !== "/auth/refreshtoken") {
        return {
          ...config,
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${tokenCheck.accessToken}`,
            ...config.headers,
          },
        };
      } else {
        return config;
      }
    });

    this.axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        const originalRequest = err.config;

        if (err.response.status === 401) {
          const preRefreshToken = localStorage.getItem(
            tokenConstant.REFRESH_TOKEN,
          );
          if (preRefreshToken) {
            return this.axiosInstance
              .post("/auth/refreshtoken", {
                refreshToken: preRefreshToken,
              })
              .then((res) => {
                const { accessToken, refreshToken } = res.data;
                localStorage.setItem(tokenConstant.ACCESS_TOKEN, accessToken);
                localStorage.setItem(tokenConstant.REFRESH_TOKEN, refreshToken);

                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return this.axiosInstance(originalRequest);
              })
              .catch(() => {
                localStorage.removeItem(tokenConstant.ACCESS_TOKEN);
                localStorage.removeItem(tokenConstant.REFRESH_TOKEN);
                window.location.href = "/";
                return false;
              });
          }
          return err;
        }
        return err;
      },
    );

    return this.axiosInstance;
  }
}
