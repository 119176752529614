import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { putManagerDetail } from "service/api/login/postApi";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { DetailTable } from "./detailTable";
import { DetailActionBtn } from "components/button";
import useSWR from "swr";
import routerConstant from "service/constant/routerConstant";

export interface IChangeData {
  password: string;
  adminId: string;
  nickName: string;
  isSuperAdmin: boolean;
  isCommunity: boolean;
  isLecture: boolean;
  isProblem: boolean;
  createdAt: string;
}

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export function ManagerDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [changeData, setChangeData] = useState<IChangeData>({} as IChangeData);
  const [adminOption, setAdminOption] = useState<string[]>([]);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  const { data } = useSWR(`/admin/manager/${params.id}`);

  useEffect(() => {
    if (data) {
      setChangeData({ ...data.data, password: "password" });
      setAdminOption([
        data.data.isCommunity && "community",
        data.data.isLecture && "lecture",
        data.data.isProblem && "problem",
      ]);
    }
  }, [data]);

  const handleCancelBtn = () => {
    navigate(-1);
  };
  console.log(changeData);
  const handleSaveBtn = async () => {
    if (changeData.password && changeData.nickName) {
      const res = await putManagerDetail(params.id + "", {
        password:
          changeData.password === "password" ? null : changeData.password,
        nickName: changeData.nickName,
        isCommunity: adminOption.includes("community"),
        isLecture: adminOption.includes("lecture"),
        isProblem: adminOption.includes("problem"),
      });
      if (res.status === 200) {
        setIsSuccessModalVisible(true);
      }
    } else {
      window.alert("항목을 입력해주세요");
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.user.manager);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <HeadText label="운영자 관리 상세" />
      <DetailTable
        changeData={changeData}
        setChangeData={setChangeData}
        adminOption={adminOption}
        setAdminOption={setAdminOption}
      />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="목록으로"
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
