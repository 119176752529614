import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import styleConstant from "service/constant/styleConstant";

interface Props {
  onClick?: () => void;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
}

const CustomModal = styled(Modal)`
  .ant-modal-content {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    .ant-modal-body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0 0 35px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
`;

const Btn = styled.button`
  width: 100%;
  height: 46px;
  border: none;
  border-radius: 6px;
  background-color: ${styleConstant.COLOR.main_O};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export function OneBtnModal({ isVisible, setIsVisible, text, onClick }: Props) {
  const handleCancel = () => {
    setIsVisible(false);
  };
  return (
    <CustomModal
      visible={isVisible}
      maskClosable={true}
      width={400}
      centered={true}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <p>{text}</p>
      <Btn
        onClick={() => {
          onClick && onClick();
          setIsVisible(false);
        }}
      >
        확인
      </Btn>
    </CustomModal>
  );
}
