import styleConstant from "service/constant/styleConstant";
import styled from "styled-components";

interface Props {
  margin?: string;
  label: string;
}

const Head = styled.p<{ headMargin: string | undefined }>`
  margin: ${({ headMargin }) => (headMargin ? headMargin : "0")};
  line-height: 150%;
  color: ${styleConstant.COLOR.gray_1};
  font-size: 20px;
  font-weight: 600;
`;

export function HeadText({ margin, label }: Props) {
  return <Head headMargin={margin}>{label}</Head>;
}
