import React from "react";
import styled from "styled-components";
import { Menu, Dropdown } from "antd";
import styleConstant from "service/constant/styleConstant";
import { DownOutlined } from "@ant-design/icons";

interface Props {
  categoryList: { name: string; id: number }[];
  category: number;
  name: string;
  setCategory: React.Dispatch<React.SetStateAction<any>>;
}

const MenuItem = styled.p`
  margin: 0;
  padding: 8px 4px;
  color: ${styleConstant.COLOR.gray_1};
  font-size: 14px;
  cursor: pointer;
`;

const DropdownBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #171717;
  font-size: 14px;
  cursor: pointer;
  .placeholder {
    margin: 0;
    line-height: 20px;
    color: #8a92a6;
    font-size: 14px;
  }
`;

export function CategoryDropdown({
  categoryList,
  category,
  name,
  setCategory,
}: Props) {
  const handleItemClick = (id: number) => {
    setCategory((prev: any) => ({
      ...prev,
      [name]: id,
    }));
  };

  const menuItem = categoryList.map((label, idx) => ({
    label: (
      <MenuItem onClick={() => handleItemClick(label.id)}>
        {label?.name}
      </MenuItem>
    ),
    key: idx,
  }));
  const menu = <Menu items={menuItem} />;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <DropdownBox>
        {category ? (
          categoryList?.filter((list) => list.id === category)[0]?.name
        ) : (
          <p className="placeholder">선택</p>
        )}{" "}
        <DownOutlined />
      </DropdownBox>
    </Dropdown>
  );
}
