// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FONT_FAMILY: {
    SpoqaFont: "Spoqa Han Sans Neo, sans-serif",
  },
  COLOR: {
    line_1: "#E3E3E3",
    line_2: "#ECEEF2",
    gray_1: "#171717",
    gray_2: "#4A4A4A",
    gray_3: "#8B8B8B",
    gray_4: "#C1C1C1",
    gray_5: "#EBEBEB",
    main_O: "#FF8D00",
    main_B: "#00A9E7",
    main_G: "#6c757d",
    star: "#FEB72D",
    error: "#F14C4B",
    success: "#00A803",
    dark_mode_1: "#282822",
    dark_mode_2: "#1c1c17",
    dark_mode_3: "#2f2f2a",
    dark_mode_4: "#161613",
  },
};
