import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom"
import {getBannerDetail} from "service/api/content/getApi"
import {postBanner, putBanner} from "service/api/content/postApi"
import {OneBtnModal} from "components/modal"
import {HeadText} from "components/header"
import {BannerDetailTable} from "./detailTable"
import {DetailActionBtn} from "components/button"
import type {IBannerDetail} from "../banner.interface"
import routerConstant from "service/constant/routerConstant";

const BtnContainer = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 16px;
margin: 36px 0 0;
`

export function BannerDetailPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false)
    const [data, setData] = useState<IBannerDetail>({
        id: 0,
        title: "",
        subTitle: "",
        src: "",
        link: ""
    })

    useEffect(() => {
        if (location.pathname.split("/").slice(-1)[0] !== "add") {
          (async () => {
            const detailRes = await getBannerDetail(
              location.pathname.split("/").slice(-1)[0],
            );
            if(detailRes.status === 200) {
                setData(detailRes.data.data)
            }
          })();
        }
      }, [location.pathname]);

      const handleCancelBtn = () => {
        navigate(-1)
      }

      const handleSaveBtn = async() => {
        if(data.link && data.src) {
            if (location.pathname.split("/").slice(-1)[0] === "add") {
                const addRes = await postBanner({
                    isActive: true,
                    title: data.title,
                    subTitle: data.subTitle,
                    src: data.src,
                    link: data.link
                })
                if(addRes.status===201) {
                    setIsSuccessModalVisible(true)
                }
            } else {
                const putRes = await putBanner(data.id+"", {
                    isActive: true,
                    title: data.title,
                    subTitle: data.subTitle,
                    src: data.src,
                    link: data.link
                })
                if(putRes.status===200) {
                    setIsSuccessModalVisible(true)
                }
            }
        } else {
            window.alert("이미지, 링크는 필수값입니다.")
        }
      }

      const handleSuccessModalBtn = () => {
        navigate(routerConstant.contents.banner)
      }

    return (
        <>
        <OneBtnModal isVisible={isSuccessModalVisible} setIsVisible={setIsSuccessModalVisible} text="저장되었습니다." onClick={handleSuccessModalBtn} />
            <HeadText label="배너 상세" />
            <BannerDetailTable data={data} setData={setData} />
            <BtnContainer>
                <DetailActionBtn label="목록으로" isFill={false} onClick={handleCancelBtn} />
                <DetailActionBtn label="저장하기" isFill onClick={handleSaveBtn} />
            </BtnContainer>
        </>
    )
}