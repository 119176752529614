import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { postVideo, putVideo } from "service/api/content/postApi";
import { getVideo } from "service/api/content/getApi";
import { OneBtnModal } from "components/modal";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { VideoDetailTable } from "./detailTable";
import routerConstant from "service/constant/routerConstant";

export interface IChangeData {
  title: string;
  content_id: string;
}

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export function VideoDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [changeData, setChangeData] = useState<IChangeData>({} as IChangeData);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getVideo(params.id + "");

        setChangeData({
          title: res.data.data.title,
          content_id: res.data.data.content_id,
        });
      })();
    }
  }, [params]);

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const handleSaveBtn = async () => {
    if (changeData.title && changeData.content_id) {
      if (params.id === "add") {
        const res = await postVideo(changeData);
        res.status === 201 && setIsSuccessModalVisible(true);
      } else {
        const res = await putVideo(params.id + "", changeData);
        res.status === 200 && setIsSuccessModalVisible(true);
      }
    }
  };

  const handleSuccessBtn = () => {
    navigate(routerConstant.contents.video);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다"
        onClick={handleSuccessBtn}
      />
      <HeadText label="영상 상세" />
      <VideoDetailTable data={changeData} setData={setChangeData} />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="목록으로"
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
}
