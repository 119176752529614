import React from "react";
import styled from "styled-components";
import { Table } from "antd";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
  selectProblem: number[];
  setSelectProblem: React.Dispatch<React.SetStateAction<number[]>>;
}

const CustomTable = styled(Table)`
  margin: 17px 0 8px;
`;

export function AddProblemTable({
  data,
  count,
  selectPage,
  setSelectPage,
  selectProblem,
  setSelectProblem,
}: Props) {
  const columns = [
    {
      title: "문제ID",
      dataIndex: "id",
      width: "15%",
    },
    {
      title: "문제명",
      dataIndex: "title_ko",
    },
  ];

  const handleSelectTableChange = (selectTable: any) => {
    setSelectProblem(selectTable);
  };

  const rowSelection: any = {
    type: "radio",
    selectedRowKeys: selectProblem,
    onChange: handleSelectTableChange,
  };

  return (
    <CustomTable
      rowKey="id"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={{
        current: selectPage,
        pageSize: 5,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
