import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { HeadText } from "components/header";
import { TableInput } from "components/input";
import { SearchTable } from "components/table";
import { MemberTable } from "./memberTable";

const Cover = styled.div``;

export function MemberPage() {
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [submitSearch, setSubmitSearch] = useState<string>("");
  const [selectPage, setSelectPage] = useState<number>(1);

  const { data } = useSWR(`/admin/user?search=${submitSearch}`);

  useEffect(() => {
    if (searchInputValue.length === 0) {
      setSubmitSearch("");
    }
  }, [searchInputValue]);

  const handleSearchBtn = () => {
    setSubmitSearch(searchInputValue);
  };

  return (
    <Cover>
      <HeadText label="회원 관리" margin="0 0 25px" />
      <SearchTable
        rightTitle="검색어"
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        onClick={handleSearchBtn}
      />
      <MemberTable
        data={data?.data}
        count={data?.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
      />
    </Cover>
  );
}
