import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getLectureDetail } from "service/api/content/getApi";
import { postLecture, putLecture } from "service/api/content/postApi";
import { OneBtnModal } from "components/modal";
import { useNavigate, useParams } from "react-router-dom";
import { HeadText } from "components/header";
import { DetailTable } from "./detailTable";
import Editor from "components/editor";
import { CurriculumContainer } from "./curriculumContainer";
import { DetailActionBtn } from "components/button";
import routerConstant from "service/constant/routerConstant";

export interface IChangeData {
  isActive: boolean;
  title: string;
  teacher: string;
  thumbnail: string;
  level: number;
  language: number;
  description: string;
  tag: string;
  CategoryId: number;
  units: {
    title: string;
    Videos: { id: number; title: string; sequence: number }[];
    Problems: { id: number; title: string }[];
  }[];
}

const Cover = styled.div``;

const EditorContainer = styled.div`
  margin: 0 0 93px;
  .editorTitle {
    margin: 0 0 15px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const LectureDetail: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [changeData, setChangeData] = useState<IChangeData>({
    isActive: false,
  } as IChangeData);
  const [contentValue, setContentValue] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const [isErrModalVisible, setIsErrModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (params.id !== "add") {
      (async () => {
        const res = await getLectureDetail(params.id + "");
        const {
          isActive,
          title,
          teacher,
          thumbnail,
          level,
          language,
          description,
          content,
          tag,
          CategoryId,
        } = res.data.data;
        if (res.status === 200) {
          let units = res.data.data.LectureUnits.map((unitList: any) => ({
            ...unitList,
            Problems: unitList.Problems.map((problemList: any) => ({
              id: problemList.id,
              title: problemList.title_ko,
            })),
            Videos: unitList.Videos.map((videoList: any, idx: number) => ({
              id: videoList.id,
              title: videoList.title,
              sequence: videoList.LectureUnitVideo.sequence,
            })),
          }));

          setChangeData({
            isActive,
            title,
            teacher,
            thumbnail,
            level,
            language,
            description,
            tag,
            units: units,
            CategoryId,
          });
          setContentValue(content);
          setIsUpdated(true);
        }
      })();
    }
  }, [params]);

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const handleSaveBtn = async () => {
    if (params.id === "add") {
      const saveRes = await postLecture({
        ...changeData,
        content: contentValue,
      });
      if (saveRes.status === 201) {
        setIsSuccessModalVisible(true);
      } else if (saveRes.status === 220) {
        setIsErrModalVisible(true);
      }
    } else {
      const saveRes = await putLecture(params.id + "", {
        ...changeData,
        content: contentValue,
      });
      if (saveRes.status === 200) {
        setIsSuccessModalVisible(true);
      } else if (saveRes.status === 220) {
        setIsErrModalVisible(true);
      }
    }
  };

  const handleSuccessModalOkBtn = () => {
    navigate(routerConstant.contents.lecture);
  };
  return (
    <Cover>
      <OneBtnModal
        isVisible={isSuccessModalVisible}
        setIsVisible={setIsSuccessModalVisible}
        text="저장 완료되었습니다."
        onClick={handleSuccessModalOkBtn}
      />
      <OneBtnModal
        isVisible={isErrModalVisible}
        setIsVisible={setIsErrModalVisible}
        text="권한이 없습니다"
      />
      <HeadText label="강의 상세" />
      <DetailTable data={changeData} setData={setChangeData} />
      <EditorContainer>
        <p className="editorTitle">강의소개</p>
        {!isUpdated && <Editor height="504px" setValue={setContentValue} />}
        {isUpdated && (
          <Editor
            height="504px"
            value={contentValue}
            setValue={setContentValue}
          />
        )}
      </EditorContainer>
      <CurriculumContainer data={changeData} setData={setChangeData} />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="취소하기"
          onClick={handleCancelBtn}
        />
        <DetailActionBtn label="저장하기" onClick={handleSaveBtn} />
      </BtnContainer>
    </Cover>
  );
};

export default LectureDetail;
