import React, { useState } from "react";
import styled from "styled-components";
import { delInform } from "service/api/community";
import { useNavigate } from "react-router-dom";
import { HeadText } from "components/header";
import { DetailActionBtn } from "components/button";
import { ListTable } from "./listTable";
import useSWR from "swr";
import routerConstant from "service/constant/routerConstant";

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 33px 0 26px;
`;

export function InformPage() {
  const navigate = useNavigate();
  const [selectTable, setSelectTable] = useState<string[]>([]);
  const [selectPage, setSelectPage] = useState<number>(1);

  const { data, mutate } = useSWR(
    `/inform?limit=30&offset=${(selectPage - 1) * 30}`,
  );

  const handleDeleteBtn = async () => {
    const res = await delInform(selectTable.join(";") + ";");
    mutate();
  };

  const handleAddBtn = () => {
    navigate(`${routerConstant.community.inform}/add`);
  };

  return (
    <Cover>
      <HeadText label="공지사항" />
      <BtnContainer>
        <DetailActionBtn
          label="선택삭제"
          isFill={false}
          onClick={handleDeleteBtn}
        />
        <DetailActionBtn label="+등록하기" onClick={handleAddBtn} />
      </BtnContainer>
      <ListTable
        data={data?.data}
        count={data?.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
        selectTable={selectTable}
        setSelectTable={setSelectTable}
      />
    </Cover>
  );
}
