import React from "react";
import styled from "styled-components";
import { DatePicker } from "antd";
import styleConstant from "service/constant/styleConstant";
import moment from "moment";

interface Props {
  margin?: string;
  searchInputValue: string;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<
    React.SetStateAction<{ start: string | undefined; end: string | undefined }>
  >;
  onClick: () => void;
}

const Cover = styled.div<{ tableMargin: string | undefined }>`
  display: flex;
  /* gap: 40px; */
  justify-content: space-between;
  margin: ${({ tableMargin }) => (tableMargin ? tableMargin : "0")};
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .center {
    display: flex;
    /* gap: 20px; */
  }
  .datePicker {
    width: 398px;
  }
`;

const Article = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 51px;
  padding: 13px 33px;
  background-color: #e8edf2;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 392px;
  height: 51px;
  padding: 16px;
  border: none;
  outline: none;
  font-size: 16px;
`;

const Btn = styled.button`
  width: 78px;
  height: 51px;
  border: none;
  background-color: ${styleConstant.COLOR.main_G};
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

export function DateSearchTable({
  margin,
  searchInputValue,
  setSearchInputValue,
  setDate,
  onClick,
}: Props) {
  const { RangePicker } = DatePicker;
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClick();
    }
  };

  const handleDateInput = (date: any, stringDate: string[]) => {
    setDate({
      start: stringDate[0],
      end: stringDate[1],
    });
  };

  return (
    <Cover tableMargin={margin}>
      <div className="center">
        <Article>날짜</Article>
        <div className="datePicker">
          <RangePicker
            onChange={handleDateInput}
            style={{ width: "100%", height: "100%", border: "none" }}
          />
        </div>
      </div>
      <div className="center">
        <Article>검색어</Article>
        <Input
          value={searchInputValue}
          onChange={handleSearchInput}
          onKeyDown={handleInputKeyPress}
        />
        <Btn onClick={onClick}>조회</Btn>
      </div>
    </Cover>
  );
}
