// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "/login",
  user: {
    member: "/user/member",
    manager: "/user/manager",
  },
  contents: {
    lecture: "/contents/lecture",
    video: "/contents/video",
    problem: "/contents/problem",
    category: "/contents/category",
    roadmap: "/contents/roadmap",
    banner: "/contents/banner",
    popularLecture: "/contents/popular_lecture",
  },
  membership: {
    price: "/membership/price",
    list: "/membership/list",
  },
  community: {
    qna: "/community/qna",
    study: "/community/study",
    knowledge: "/community/knowledge",
    review: "/community/review",
    inform: "/community/inform",
  },
  terms: "/terms",
};
