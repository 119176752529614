import React from "react";
import styled from "styled-components";
import type { IData } from "./add";
import { Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

interface Props {
  data: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
}

const Table = styled.table`
  width: 100%;
  margin: 32px 0 37px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 75px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 75px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
`;

const Input = styled.input`
  width: 328px;
  height: 51px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  &::placeholder {
    color: #8a92a6;
  }
`;

const CustomCheckBox = styled(Checkbox)`
  margin: 0;
  color: #111827;
  font-size: 16px;
`;

export function AddTable({ data, setData }: Props) {
  const handleAdminIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      adminId: e.target.value,
    }));
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      password: e.target.value,
    }));
  };

  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleCheckBoxClick = (checkedValues: CheckboxValueType[]) => {
    setData((prev) => ({
      ...prev,
      option: checkedValues,
    }));
  };

  const options = [
    { label: "커뮤니티", value: "community" },
    { label: "강의", value: "lecture" },
    { label: "문제", value: "problem" },
  ];

  return (
    <Table>
      <tbody>
        <tr>
          <th>아이디*</th>
          <td colSpan={3}>
            <Input
              placeholder="아이디 입력"
              value={data.adminId}
              onChange={handleAdminIdInput}
            />
          </td>
        </tr>
        <tr>
          <th>비밀번호*</th>
          <td>
            <Input
              type="password"
              placeholder="비밀번호 입력"
              value={data.password}
              onChange={handlePasswordInput}
            />
          </td>
          <th>이름*</th>
          <td>
            <Input
              placeholder="이름 입력"
              value={data.name}
              onChange={handleNameInput}
            />
          </td>
        </tr>
        <tr>
          <th>계정 권한</th>
          <td>
            <CustomCheckBox.Group
              options={options}
              value={data.option}
              onChange={handleCheckBoxClick}
            />{" "}
          </td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
}
