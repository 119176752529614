import React from "react";
import styled from "styled-components";
import styleConstant from "service/constant/styleConstant";

interface Props {
  rightTitle: string;
  searchInputValue: string;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  onClick: () => void;
}

const Cover = styled.div`
  display: flex;
  /* gap: 40px; */
  justify-content: space-between;
  margin: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .center {
    display: flex;
    /* gap: 20px; */
  }
`;

const Article = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 130px;
  height: 51px;
  padding: 13px 33px;
  background-color: #e8edf2;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 901px;
  height: 51px;
  padding: 16px;
  border: none;
  outline: none;
  font-size: 16px;
`;

const Btn = styled.button`
  width: 78px;
  height: 51px;
  border: none;
  background-color: ${styleConstant.COLOR.main_G};
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

export function SearchTable({
  rightTitle,
  searchInputValue,
  setSearchInputValue,
  onClick,
}: Props) {
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClick();
    }
  };

  return (
    <Cover>
      <div className="center">
        <Article>{rightTitle}</Article>
        <Input
          value={searchInputValue}
          onChange={handleSearchInput}
          onKeyDown={handleInputKeyPress}
        />
        <Btn onClick={onClick}>조회</Btn>
      </div>
    </Cover>
  );
}
