import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import moment from "moment";
import routerConstant from "service/constant/routerConstant";

interface Props {
  data: any;
  count: number;
  selectPage: number;
  setSelectPage: React.Dispatch<React.SetStateAction<number>>;
}

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #6b7280;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    color: #111827;
    font-size: 14px;
  }
  .routerBtn {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export function ListTable({ data, count, selectPage, setSelectPage }: Props) {
  const navigate = useNavigate();
  const typeMap: { [key: string]: any } = {
    0: "기타",
    1: "강의",
    2: "문제",
  };
  const handleDetailBtn = (id: string) => {
    navigate(`${routerConstant.community.qna}/${id}`);
  };

  const columns = [
    {
      title: "NO",
      dataIndex: "id",
      width: "7%",
    },
    {
      title: "등록일",
      dataIndex: "createdAt",
      width: "13%",
      render: (text: string) => <>{moment(text).format("YYYY.MM.DD")}</>,
    },
    {
      title: "작성자",
      dataIndex: "User",
      width: "10%",
      render: (user: any) => <>{user?.nickName}</>,
    },
    {
      title: "구분",
      dataIndex: "type",
      width: "8%",
      render: (text: string) => <>{typeMap[text]}</>,
    },
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "답변수",
      dataIndex: "Comments",
      width: "7%",
      render: (comment: any) => <>{comment ? comment?.length : "0"}</>,
    },
    {
      title: "상세보기",
      dataIndex: "id",
      width: "8%",
      render: (id: number) => (
        <span className="routerBtn" onClick={() => handleDetailBtn(id + "")}>
          바로가기
        </span>
      ),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      dataSource={data}
      pagination={{
        current: selectPage,
        pageSize: 30,
        onChange: (page) => setSelectPage(page),
        total: count,
        showSizeChanger: false,
      }}
    />
  );
}
