import React, { useState } from "react";
import styled from "styled-components";
import { HeadText } from "components/header";
import { DateSearchTable } from "components/table";
import { ListTable } from "./listTable";
import useSWR from "swr";

const Cover = styled.div``;

export function ReviewPage() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateValue, setDateValue] = useState<{
    start: string | undefined;
    end: string | undefined;
  }>({ start: undefined, end: undefined });
  const [submitValue, setSubmitValue] = useState<{
    search?: string;
    endDate?: string;
    startDate?: string;
  }>({});
  const [selectPage, setSelectPage] = useState<number>(1);

  const { data } = useSWR(
    `/admin/review?limit=30&offset=${(selectPage - 1) * 30}&startDate=${
      submitValue?.startDate ? submitValue.startDate : ""
    }&endDate=${submitValue?.endDate ? submitValue.endDate : ""}&search=${
      submitValue?.search ? submitValue.search : ""
    }`,
  );

  const handleSearchBtn = () => {
    setSubmitValue({
      search: searchValue,
      endDate: dateValue.end,
      startDate: dateValue.start,
    });
  };

  return (
    <Cover>
      <HeadText label="수강후기" />
      <DateSearchTable
        margin="25px 0 45px"
        searchInputValue={searchValue}
        setSearchInputValue={setSearchValue}
        setDate={setDateValue}
        onClick={handleSearchBtn}
      />
      <ListTable
        data={data?.data}
        count={data?.count}
        selectPage={selectPage}
        setSelectPage={setSelectPage}
      />
    </Cover>
  );
}
