import React from "react";
import { Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { IChangeData } from "./detail";

interface Props {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<IChangeData>>;
}

export function LanguageCheckBox({ data, setData }: Props) {
  const handleBoxClick = (value: any) => {
    setData((prev) => ({
      ...prev,
      language: value,
    }));
  };

  const options = ["C", "C++", "Java", "Python"];

  return (
    <Checkbox.Group options={options} value={data} onChange={handleBoxClick} />
  );
}
