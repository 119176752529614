import moment from "moment";
import React from "react";
import styled from "styled-components";

interface Props {
  data: any;
  changeData: {
    banCommunityDate: string;
    isActive: boolean;
    memo: string;
  };
  setChangeData: React.Dispatch<
    React.SetStateAction<{
      banCommunityDate: string;
      isActive: boolean;
      memo: string;
    }>
  >;
  banCount: number;
  setBanCount: React.Dispatch<React.SetStateAction<number>>;
}

const Cover = styled.div`
  margin: 0 0 30px;
  .tableTitle {
    margin: 0 0 20px;
    line-height: 175%;
    color: #232d42;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  th {
    width: 196px;
    height: 52px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f5f6fa;
    color: #8a92a6;
    font-size: 16px;
    font-weight: 400;
  }
  td {
    width: 358px;
    height: 51px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #111827;
    font-size: 16px;
  }
  .community {
    height: 75px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 22px;
  label {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
`;

const Input = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 328px;
  height: 51px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  input {
    width: 260px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export function DetailTable({
  data,
  changeData,
  setChangeData,
  banCount,
  setBanCount,
}: Props) {
  const levelMap: { [key: string]: string } = {
    0: "브론즈",
    1: "실버",
    2: "골드",
    3: "플래티넘",
    4: "다이아",
    5: "마스터",
  };

  const handleIsActiveInputChange = (isActive: boolean) => {
    setChangeData((prev: any) => ({
      ...prev,
      isActive,
    }));
  };

  const handleBanInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!/[^0-9]/g.test(e.target.value)) {
      setBanCount(Number(e.target.value));
    }
  };

  return (
    <Cover>
      <p className="tableTitle">기본정보</p>
      <Table>
        <tbody>
          <tr>
            <th>회원상태</th>
            <td>
              <StatusContainer>
                <label htmlFor="active">
                  <input
                    type="radio"
                    name="isActive"
                    id="active"
                    checked={changeData?.isActive}
                    onChange={() => handleIsActiveInputChange(true)}
                  />
                  활성
                </label>
                <label htmlFor="nonActive">
                  <input
                    type="radio"
                    name="isActive"
                    id="nonActive"
                    checked={!changeData?.isActive}
                    onChange={() => handleIsActiveInputChange(false)}
                  />
                  비활성
                </label>
              </StatusContainer>
            </td>
            <th>멤버쉽</th>
            <td>
              {data?.UserMemberShips.length > 0
                ? `Y(${moment(data?.UserMemberShips[0].expiredAt).format(
                    "YYYY.MM.DD",
                  )}까지)`
                : "N"}
            </td>
          </tr>
          <tr>
            <th>이메일</th>
            <td>{data?.email}</td>
            <th>닉네임</th>
            <td>{data?.nickName}</td>
          </tr>
          <tr>
            <th>핸드폰</th>
            <td>{data?.phoneNumber}</td>
            <th>로그인방식</th>
            <td>{data?.loginType}</td>
          </tr>
          <tr>
            <th>회원구분</th>
            <td></td>
            <th>보호자 핸드폰</th>
            <td></td>
          </tr>
          <tr>
            <th>회원등급</th>
            <td>{levelMap[data?.level]}</td>
            <th>가입일시</th>
            <td>{moment(data?.createdAt).format("YYYY.MM.DD HH:mm:ss")}</td>
          </tr>
          <tr>
            <th>최근 로그인</th>
            <td>-</td>
            <th>탈퇴일시</th>
            <td>
              {data?.deletedAt
                ? moment(data?.deletedAt).format("YYYY.MM.DD HH:mm:ss")
                : "-"}
            </td>
          </tr>
          <tr>
            <th className="community">커뮤니티 사용금지</th>
            <td className="community" colSpan={3}>
              <Input>
                <input value={banCount} onChange={handleBanInputChange} /> 일
              </Input>
            </td>
          </tr>
        </tbody>
      </Table>
    </Cover>
  );
}
