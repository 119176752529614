import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HeadText } from "components/header";
import { AddTable } from "./addTable";
import { OneBtnModal } from "components/modal";
import { DetailActionBtn } from "components/button";
import { postJoin } from "service/api/login/postApi";
import responseConstant from "service/constant/responseConstant";
import routerConstant from "service/constant/routerConstant";

export interface IData {
  adminId: string;
  password: string;
  name: string;
  option: any[];
}

const Cover = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export function ManagerAddPage() {
  const navigate = useNavigate();
  const [data, setData] = useState<IData>({} as IData);
  const [isSubmitModalVisible, setIsSubmitModalVisible] =
    useState<boolean>(false);
  const [isErrorModalVisible, setIsErrorModalVisible] =
    useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleCnacelBtn = () => {
    navigate(-1);
  };

  const handleAddBtn = async () => {
    if (data.adminId && data.password && data.name) {
      const res = await postJoin({
        adminId: data.adminId,
        password: data.password,
        nickName: data.name,
        isCommunity: data.option.includes("community"),
        isLecture: data.option.includes("lecture"),
        isProblem: data.option.includes("problem"),
      });
      if (res.status === 201) {
        setIsSubmitModalVisible(true);
      } else {
        setErrorMsg("중복된 아이디 입니다.");
        setIsErrorModalVisible(true);
      }
    } else {
      setErrorMsg("항목을 입력해주세요 ");
      setIsErrorModalVisible(true);
    }
  };

  const handleAddSubmitBtn = () => {
    navigate(routerConstant.user.manager);
  };

  return (
    <Cover>
      <OneBtnModal
        isVisible={isSubmitModalVisible}
        setIsVisible={setIsSubmitModalVisible}
        text="등록 완료되었습니다."
        onClick={handleAddSubmitBtn}
      />
      <OneBtnModal
        isVisible={isErrorModalVisible}
        setIsVisible={setIsErrorModalVisible}
        text={errorMsg}
      />
      <HeadText label="관리자 등록" />
      <AddTable data={data} setData={setData} />
      <BtnContainer>
        <DetailActionBtn
          isFill={false}
          label="취소하기"
          onClick={handleCnacelBtn}
        />
        <DetailActionBtn label="등록하기" onClick={handleAddBtn} />
      </BtnContainer>
    </Cover>
  );
}
