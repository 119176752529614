import tokenConstant from "service/constant/tokenConstant";

export default class TokenManager {
  private static accessToken: string | null;

  public static getAccessToken() {
    this.accessTokenCheck();
    return {
      accessToken: this.accessToken,
    };
  }

  private static accessTokenCheck() {
    if (typeof window !== "undefined") {
      this.accessToken = localStorage.getItem(tokenConstant.ACCESS_TOKEN);
    }
    return {
      accessToken: this.accessToken,
    };
  }
}
